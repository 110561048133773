import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Card, Carousel, Collapse, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import panelData from "./panelData.json";
import {
  PanelContainer,
  Banner,
  StyledButton,
  Intro,
  Wrapper,
  CustomTimeline,
  CustomSyllabus,
  CustomButton,
  CustomStyle,
  JobRoles,
  Certifications,
  GetInTouch,
  CustomCarousel,
  CustomOrganizer,
} from "./index.style";
import { HiDownload } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import {
  certificationsData,
  dataLevel,
  jobRolesData,
  sectionsData,
  syllabusData,
} from "./store/introData";
import { Timeline } from "antd";
import { HiOutlineGlobeAlt } from "react-icons/hi2";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { testimonialsData } from "./store/introData";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { MetaHelmet } from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";
import CustomImage from "../../common/images";

const { Panel: AntdPanel } = Collapse;
// eslint-disable-next-line no-unused-vars
const { Text } = Typography;
const { Meta } = Card;
const IntroPage = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const [panelsData, setPanelsData] = useState([]);
  useEffect(() => {
    setPanelsData(panelData);
  }, []);

  function scrollToSection() {
    const section = document.getElementById("multi-level");
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        duration: 3000,
      });
    }
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      carouselRef.current.prev();
    }
  };

  const nextSlide = () => {
    if (currentIndex < testimonialsData.length - 3) {
      setCurrentIndex(currentIndex + 1);
      carouselRef.current.next();
    }
  };

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };
  return (
    <Wrapper>
      <MetaHelmet
        {...metaHelmetData.beginners_online_sql_training_course_learn_sql_certification}
      />
      <Intro>
        <div className="introContainer">
          <p className="heading">Do you want to get a software job?</p>
          <h1 className="subHeading">
            Learn SQL in just 30 days with ZERO IT knowledge
          </h1>
          <p className="paragraph">
            Learn your first database programming language and aim for software
            job roles such as SQL Developer and Data Analyst.
          </p>
          <Button className="button" onClick={scrollToSection}>
            Our Training Methodology
          </Button>
        </div>
      </Intro>

      <Banner>
        <div className="headingContainer">
          <div className="title">
            WELCOME TO TANSY ACADEMY's ONLINE SQL TRAINING COURSE
          </div>
          <div className="subtitle">
            Boost your Career with Job oriented SQL training classes.
          </div>
          <div className="iconContainer">
            <Link to="https://tansyacademy.com/course_detail?name=Microsoft%20SQL%20Server">
              <CustomImage
                src="/icons/ms-sql-server-intropage.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link to="https://tansyacademy.com/course_detail?name=MySQL">
              <CustomImage
                src="/icons/mysql-word-intropage.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link to="https://tansyacademy.com/course_detail?name=PostgreSQL">
              <CustomImage
                src="/icons/postgresql-intropage.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link to="https://tansyacademy.com/course_detail?name=Oracle">
              <CustomImage
                src="/icons/oracle-intropage.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link to="https://tansyacademy.com/course_detail?name=MySQL">
              <CustomImage
                src="/icons/mysql-intropage.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
          </div>
          <div className="buttonContainer">
            <Link
              to={"/learn_sql_beginners_online_sql_training_course.pdf"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledButton icon={<HiDownload size={20} />}>
                Download SQL course syllabus
              </StyledButton>
            </Link>
          </div>
        </div>
        <div>
          <img
            className="sqlimg"
            src="/images/sql-illustration.svg"
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </div>
      </Banner>

      <PanelContainer>
        <Collapse
          defaultActiveKey={["1", "2", "3", "4", "5", "6", "7"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
          expandIconPosition="end"
        >
          {panelsData?.map((panel) => (
            <AntdPanel
              key={panel.key}
              header={<p className="panelHeader">{panel.header}</p>}
              className="customPanel"
            >
              <div dangerouslySetInnerHTML={{ __html: panel.text }} />
            </AntdPanel>
          ))}
        </Collapse>
      </PanelContainer>

      <div className="include">
        <Link className="requirements">
          Requirements for this SQL Class include
        </Link>
      </div>

      <div className="access ">
        <p>
          <img
            src="/icons/material-symbols_computer-outline.svg "
            alt="i"
            width="100%"
            height="100%"
            loading="lazy"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
          Access to a personal computer
        </p>
        <p>
          <img
            src="/icons/mingcute_time-line.svg "
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
          Time commitment towards zoom class and assignments
        </p>
        <p>
          <img
            src="/icons/icon-park-outline_list-alphabet.svg"
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
          Basic English communication skills
        </p>
        <p>
          <img
            src="/icons/system-uicons_no-sign.svg"
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
          No prior software knowledge
        </p>
      </div>
      <div className="key-feature">
        {sectionsData?.map(({ id, image, title, items }, sectionIndex) => (
          <Row
            key={id}
            className={sectionIndex % 2 === 0 ? "flex-row-reverse" : "flex-row"}
            style={{ paddingBottom: "20px" }}
          >
            <Col className="wrapper" xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="content-wrapper">
                <Link className="title">{title}</Link>
                <ul className="list">
                  {items?.map((item, i) => (
                    <li key={i} className="list-item">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} className="image-wrapper">
              {image && <img src={image} alt={title} className="image" />}
            </Col>
          </Row>
        ))}
      </div>

      <div id="multi-level" />

      <div className="include ">
        <div className=" level">
          <Link className="requirements">
            Tansy Academy’s Multi-level training methodology
          </Link>
        </div>
      </div>

      <div className="include">
        <CustomTimeline>
          {dataLevel?.map((panel, i) => (
            <Timeline.Item key={i}>
              <Collapse
                defaultActiveKey={dataLevel?.map((_, index) => `${index}`)}
                className="site-collapse-custom-collapse"
                expandIconPosition="end"
              >
                <AntdPanel
                  header={<Link className="linkStyle">{panel.title}</Link>}
                  className="customPanel"
                >
                  <>
                    <h6 className="titleStyle">{panel.heading}</h6>
                    <p className="contentStyle">{panel.content}</p>
                  </>
                </AntdPanel>
              </Collapse>
            </Timeline.Item>
          ))}
        </CustomTimeline>
      </div>
      <CustomSyllabus>
        <div className="chapters">
          <p className="title">SQL Course Syllabus</p>
          <p className="description">10 Chapters</p>
        </div>
        <Collapse
          bordered={false}
          defaultActiveKey={syllabusData?.map((p, index) => `${p.key}`)}
          expandIconPosition="end"
          collapsible="icon"
        >
          {syllabusData?.map((panel) => (
            <AntdPanel
              key={panel.key}
              header={
                <>
                  <p className="heading">{panel.header}</p>
                  <p className="main">{panel.main}</p>
                </>
              }
              className="customPanel"
            >
              <div class="container">
                <div class="item">
                  <img
                    src="/icons/lesson-intropage.svg"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                    class="img-size"
                  />
                  <span class="lesson">{panel.lesson}</span>
                  <span class="lesson">{panel.lessontext}</span>
                </div>
                <div class="item">
                  <img
                    src="/icons/assign-intropage.svg"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                    class="img-size"
                  />
                  <span class="lesson">{panel.assign}</span>
                  <span class="lesson">{panel.assigntext}</span>
                </div>
              </div>
            </AntdPanel>
          ))}
        </Collapse>
      </CustomSyllabus>
      <CustomStyle>
        <Link to="/learn_sql_power_bi_analytics_online_training_cost">
          <CustomButton>Start Now</CustomButton>
        </Link>
      </CustomStyle>
      <CustomCarousel>
        <h2 className="carousel-heading">
          What our Students say
          <span>
            <img
              src="/icons/testimonial-message-icon.svg"
              alt="i"
              width="100%"
              height="100%"
              onError={(e) => {
                e.target.style.display = "none";
              }}
            />
          </span>
        </h2>

        <Carousel
          className="carousel-container"
          ref={carouselRef}
          afterChange={handleSlideChange}
          dots={false}
          centerMode={true}
          centerPadding="10px"
          autoplay={false}
          initialSlide={0}
          slidesToShow={3}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {testimonialsData?.map((testimonial, index) => (
            <div key={index} className="carousel-item">
              <Card
                hoverable={false}
                className={currentIndex === index ? "highlighted" : ""}
              >
                <Meta
                  title={testimonial.date}
                  description={`“ ${testimonial.message} ”`}
                />
                <p className="occupation">{testimonial.occupation}</p>
                <p className="name">{testimonial.name}</p>
                <div className="carousel-controls">
                  {testimonial.image && (
                    <img
                      style={{ width: "85px", height: "100px" }}
                      alt={testimonial.name}
                      src={testimonial.image}
                    />
                  )}
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
        <div className="carousel-controls">
          <Button
            className="carousel-control"
            onClick={prevSlide}
            icon={
              <BsArrowLeftCircle
                size={40}
                style={{
                  color:
                    currentIndex === 0
                      ? "rgba(63, 111, 216, 0.3)"
                      : "rgba(63, 111, 216, 1)",
                }}
              />
            }
            disabled={currentIndex === 0}
          />
          <Button
            className="carousel-control"
            onClick={nextSlide}
            icon={
              <BsArrowRightCircle
                size={40}
                style={{
                  color:
                    currentIndex === testimonialsData?.length - 3
                      ? "rgba(63, 111, 216, 0.3)"
                      : "rgba(63, 111, 216, 1)",
                }}
              />
            }
            disabled={currentIndex === testimonialsData?.length - 3}
          />
        </div>
      </CustomCarousel>
      <CustomStyle>
        <JobRoles>
          <div className="job-roles-container">
            <Link>Target Database Job Roles</Link>
          </div>
          <div className="job-roles-wrapper">
            <div className="job-role">
              {jobRolesData?.map((item, index) => (
                <div className="role" key={index}>
                  {item.iconSrc && (
                    <img
                      className="role-image"
                      src={item.iconSrc}
                      alt="i"
                      width="100%"
                      height="100%"
                    />
                  )}
                  <p className="role-title">{item.title}</p>
                  <p className="role-description">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </JobRoles>
      </CustomStyle>
      <CustomStyle>
        <Certifications>
          <div className="certifications-container">
            <Link>Target SQL Certifications</Link>
          </div>
          <div className="certifications-wrapper">
            <div className="certification">
              {certificationsData?.map((item, index) => (
                <div
                  style={{ borderBottom: `3px solid ${item.color}` }}
                  className="certification-item"
                  key={index}
                >
                  {item.iconSrc && (
                    <img
                      className="certification-image"
                      src={item.iconSrc}
                      alt="i"
                      width="100%"
                      height="100%"
                    />
                  )}
                  <p className="certification-title">{item.title}</p>
                  <p className="certification-description">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Certifications>
      </CustomStyle>

      <CustomOrganizer>
        <Row className="row" gutter={[20, 20]}>
          <Col xs={{ span: 24 }} lg={{ span: 14 }} className="content">
            <div className="heading">
              <p>CEO & Head Organizer</p>
              <p>
                <Link
                  style={{ alignItems: "center" }}
                  to="https://www.linkedin.com/in/data-champion/"
                >
                  <span>
                    <FaLinkedin
                      size={26}
                      style={{ marginRight: "5px" }}
                      color="#0077B5"
                    />
                  </span>
                  Mohammed Safder Ali
                </Link>
              </p>
            </div>
            <div className="description">
              Brings 27 years of IT experience as a database professional,
              having worked in the USA, Canada, Kuwait, Saudi Arabia, UAE, and
              India. His expertise spans across diverse business domains
              including education, retail, HR, trading, banking, healthcare, and
              consumer insights.
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 10 }}
            className="imgContainer text-center"
          >
            <img
              src="/images/organizer.svg"
              alt="i"
              width="100%"
              height="100%"
              onError={(e) => {
                e.target.style.display = "none";
              }}
              className="img"
            />
          </Col>
        </Row>
      </CustomOrganizer>
      <CustomStyle>
        <GetInTouch>
          <div className="bg-image" />
          <div className="container">
            <div className="info-container">
              <div className="heading">Get in Touch</div>
              <div className="info-wrap">
                <Link className="info" to="https://tansyacademy.com/">
                  <span>
                    <HiOutlineGlobeAlt
                      size={20}
                      style={{ margin: "0 8px" }}
                      color="rgba(250, 223, 83, 1)"
                    />
                    www.tansyacademy.com
                  </span>
                </Link>
                <Link className="info" to="https://wa.me/+971504614868">
                  <span>
                    <FaWhatsapp
                      size={20}
                      style={{ margin: "0 8px" }}
                      color="rgba(37, 211, 102, 1)"
                    />
                    +971 50 461 4868
                  </span>
                </Link>
                <Link
                  className="info"
                  to="mailto:social@tansyacademy.com?subject=Inquiry&body=Hello, I would like to know more about your courses."
                >
                  <span>
                    <MdOutlineMailOutline
                      style={{ margin: "0 8px" }}
                      size={20}
                      color="rgba(255, 119, 119, 1)"
                    />
                    social@tansyacademy.com
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </GetInTouch>
      </CustomStyle>
    </Wrapper>
  );
};

export default IntroPage;
