import React, { useEffect, useState } from "react";
import { Heading } from "./index.style";
import LiveClassesCard from "./LiveClassesCard";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../../common/Loading/LoadingSpinner";
import { listLiveClasses } from "../../redux/liveClassSlice";
import { getUserProfile } from "../../redux/authSlice";
import metaHelmetData from "../../components/json/metaHelmetData.json";
import { MetaHelmet } from "../../common/helmet";

const LiveClasses = () => {
  const dispatch = useDispatch();
  const { listLiveClass, loading: liveclasLoading } = useSelector(
    (state) => state?.liveClass
  );
  const { userProfile } = useSelector((state) => state?.auth);
  const [loading, setLoading] = useState(true);
  // console.log("useprofile", userProfile)

  useEffect(() => {
    dispatch(listLiveClasses());
    const authUser = JSON.parse(localStorage.getItem("authUser")) ?? null;
    authUser?.token && dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setLoading(liveclasLoading);
  }, [liveclasLoading]);

  const liveClassArray = Array.isArray(listLiveClass?.results)
    ? listLiveClass?.results
    : [];

  const startingSoonClasses = liveClassArray?.filter(
    (course) => course.classStatus === "Class starting soon"
  );
  const inProgressClasses = liveClassArray?.filter(
    (course) => course.classStatus === "In Progress"
  );
  return (
    <Heading>
      <MetaHelmet {...metaHelmetData.learn_sql_class_online_sql_training_course}
      />
      <div className="row">
        <h1 className="heading">Classes</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col title">
            <h2 className="f-l-class">Future Classes</h2>
            <p className="len-class">
              {startingSoonClasses?.length} awaiting classes
            </p>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {startingSoonClasses.length > 0 ? (
            <LiveClassesCard
              userProfile={userProfile}
              liveClass={startingSoonClasses}
            />
          ) : (
            <div className="mt-3 mb-3 text-center">
              <h4 className="noclasses">No Future Classes</h4>
            </div>
          )}
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col title">
            <h2 className="f-l-class">Live Classes</h2>
            <p className="len-class">
              {inProgressClasses?.length} live classes
            </p>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {inProgressClasses?.length > 0 ? (
            <LiveClassesCard
              userProfile={userProfile}
              liveClass={inProgressClasses}
            />
          ) : (
            <div className="mt-3 mb-3 text-center">
              <h4 className="noclasses">No Live Classes</h4>
            </div>
          )}
        </div>
      )}
    </Heading>
  );
};

export default LiveClasses;
