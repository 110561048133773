import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, Space, Skeleton } from "antd";
import { getAdminCourses } from "../../../../redux/courseSlice";
import {
  getCourseLessons,
  updateLessonKeywords,
} from "../../../../redux/lessonSlice";
import { CustomSelect } from "../../../../common/select/index.style";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Wrapper } from "../index.style";
import { TableContainer } from "../../../adminDataEntry/adminUserSession/index.style";
import { Link } from "react-router-dom";

const UpdateTopicKeywords = () => {
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState(null);
  const [form] = Form.useForm();

  const initialValuesRef = useRef({});

  const { adminCourses = [], courseLoading } = useSelector(
    (state) => state?.course
  );
  const { courseLessons, lessonLoading, updateLoading } = useSelector(
    (state) => state?.lesson
  );

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.[0]?._id) {
      setSelectedCourse(adminCourses[0]._id);
    }
  }, [adminCourses]);

  const handleCourseSelection = useCallback(
    (courseID) => {
      if (courseID) {
        dispatch(getCourseLessons({ courseID, specifiedFields: "keywords" }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    handleCourseSelection(selectedCourse);
  }, [handleCourseSelection, selectedCourse]);

  useEffect(() => {
    if (courseLessons?.length) {
      const data = courseLessons?.map((lesson) => ({
        key: lesson?._id,
        id: lesson?._id,
        chapterName: lesson?.course[0]?.chapterName || "",
        lessonName: lesson?.name,
        lessonTitle: lesson?.title || "",
        lessonKeywords: lesson?.keywords?.join(", ") || [],
        lessonDescription: lesson?.description || "",
        interviewTitle: lesson?.interviewTitle || "",
        interviewKeywords: lesson?.interviewKeywords?.join(", ") || [],
        interviewDescription: lesson?.interviewDescription || "",
        quizTitle: lesson?.quizTitle || "",
        quizKeywords: lesson?.quizKeywords?.join(", ") || [],
        quizDescription: lesson?.quizDescription || "",
        edit: edit,
      }));
      setEditingKey(null);
      setDataSource(data);
    }
  }, [courseLessons]);

  const courseOptions = adminCourses?.map((course) => ({
    value: course?._id,
    label: course?.longName,
  }));

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setEditingKey(record.key);
    initialValuesRef.current = record;
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    editing,
    form,
    ...restProps
  }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (editing) {
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        inputRef.current?.focus();
      }
    }, [editing, form, record, dataIndex]);
    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={() => record.edit(record)}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey(null);
  };

  const onSave = async (record) => {
    try {
      const values = await form.validateFields();
      const updatedRecord = { ...record, ...values };
      if (updatedRecord) {
        const data = {
          name: updatedRecord?.lessonName,
          title: updatedRecord?.lessonTitle,
          keywords: updatedRecord?.lessonKeywords?.split(", ") || [],
          description: updatedRecord?.lessonDescription,
          interviewTitle: updatedRecord?.interviewTitle,
          interviewKeywords:
            updatedRecord?.interviewKeywords?.split(", ") || [],
          interviewDescription: updatedRecord?.interviewDescription,
          quizTitle: updatedRecord?.quizTitle,
          quizKeywords: updatedRecord?.quizKeywords?.split(", ") || [],
          quizDescription: updatedRecord?.quizDescription,
        };
        dispatch(
          updateLessonKeywords({
            data,
            selectedCourse,
            handleCourseSelection,
            onReset: cancel,
            lessonID: updatedRecord?.id,
          })
        );
      }
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 80,
  };

  const columns = [
    {
      title: "Chapter Name",
      dataIndex: "chapterName",
      key: "chapterName",
      editable: false,
      fixed: "left",
      width: 80,
    },
    {
      title: "Lesson Name",
      dataIndex: "lessonName",
      key: "lessonName",
      editable: false,
      fixed: "left",
      width: 80,
      render: (text, record) => (
        <Link to={`/admin/topic/edit/${selectedCourse}/${record.id} `}>
          {text}
        </Link>
      ),
    },
    {
      title: "Lesson",
      children: [
        {
          title: "Title",
          dataIndex: "lessonTitle",
          key: "lessonTitle",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
        {
          title: "Keywords",
          dataIndex: "lessonKeywords",
          key: "lessonKeywords",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
        {
          title: "Description",
          dataIndex: "lessonDescription",
          key: "lessonDescription",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
      ],
    },
    {
      title: "Interview",
      children: [
        {
          title: "Title",
          dataIndex: "interviewTitle",
          key: "interviewTitle",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
        {
          title: "Keywords",
          dataIndex: "interviewKeywords",
          key: "interviewKeywords",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
        {
          title: "Description",
          dataIndex: "interviewDescription",
          key: "interviewDescription",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
      ],
    },
    {
      title: "Quiz",
      children: [
        {
          title: "Title",
          dataIndex: "quizTitle",
          key: "quizTitle",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
        {
          title: "Keywords",
          dataIndex: "quizKeywords",
          key: "quizKeywords",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
        {
          title: "Description",
          dataIndex: "quizDescription",
          key: "quizDescription",
          editable: true,
          width: 80,
          ellipsis: true,
          render: (text) => <div style={ellipsisStyle}>{text}</div>,
          align: "center",
        },
      ],
    },
    {
      title: "Operation",
      dataIndex: "operation",
      fixed: "right",
      align: "center",
      width: 80,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              loading={updateLoading}
              onClick={() => onSave(record)}
              disabled={updateLoading}
              style={{
                marginTop: 8,
                backgroundColor: "#d39331",
                color: "#fff",
                width: "70px",
                fontSize: "14px",
              }}
            >
              Save
            </Button>
            <Button
              style={{ marginTop: 8, width: "70px", fontSize: "14px" }}
              type="primary"
              onClick={cancel}
              disabled={updateLoading}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            style={{ width: "70px", fontSize: "14px" }}
            type="primary"
            disabled={editingKey !== null || updateLoading}
            onClick={() => edit(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const enhanceColumns = (cols) =>
    cols?.map((col) => ({
      ...col,
      onCell: (record) => ({
        record,

        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        form,
      }),
      children: col?.children ? enhanceColumns(col.children) : undefined,
    }));

  const mergedColumns = enhanceColumns(columns);

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Update Keywords and Description</title>
      </Helmet>
      <Container fluid>
        {courseLoading ? (
          <Skeleton active size="small" shape="round" />
        ) : (
          <>
            <div className="mainHeading">
              <div>
                <h3>Update Keywords and Description</h3>
                <span className="total">
                  {courseLessons?.length} total Topics
                </span>
              </div>
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  defaultValue={selectedCourse}
                  disabled={lessonLoading || updateLoading}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(id) => {
                    setSelectedCourse(id);
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
            <div style={{ overflowX: "auto" }}>
              <Form form={form} component={false}>
                <TableContainer
                  components={{ body: { cell: EditableCell } }}
                  loading={lessonLoading}
                  dataSource={dataSource}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  size="small"
                  bordered
                  scroll={{ x: "100%" }}
                  pagination={false}
                />
              </Form>
            </div>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default UpdateTopicKeywords;
