import course from "./courseSlice";
import contact from "./contactSlice";
import testimonial from "./testimonialSlice";
import auth from "./authSlice";
import tool from "./toolSlice";
import user from "./userSlice";
import admin from "./adminSlice";
import chapter from "./chapterSlice";
import lesson from "./lessonSlice";
import forum from "./forumSlice";
import comment from "./commentSlice";
import liveClass from "./liveClassSlice";
import queryTool from "./queryToolSlice";
import jobsListing from "./jobsListingSlice";
import assignments from "./assignmentSlice";
import questions from "./questionsSlice";
import questionsRes from "./questionsResSlice";
import assignmentsRes from "./assignmentsResSlice";
import interviewQuestions from "./interviewQuestionsSlice";
import stripe from "./stripeSlice";
import payment from "./paymentSlice";
import userInfoSession from "./userInfoSlice";
import submitUrls from "./urlSlice";

const rootReducer = {
  auth,
  course,
  contact,
  testimonial,
  tool,
  user,
  admin,
  chapter,
  lesson,
  liveClass,
  forum,
  comment,
  queryTool,
  jobsListing,
  assignments,
  questions,
  questionsRes,
  assignmentsRes,
  interviewQuestions,
  stripe,
  payment,
  userInfoSession,
  submitUrls,
};

export default rootReducer;
