import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export function MetaHelmet({
  title,
  description,
  keywordContent,
  canonicalUrl,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  ogType,
  creatorName,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterUrl,
}) {
  return (
    <Helmet>
      <title>{title || window.location.href}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywordContent} />
      <meta property="og:type" content={ogType || "website"} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta
        property="og:image"
        content={ogImage || "https://tansyacademy.com/images/logo.svg"}
      />
      <meta property="og:url" content={ogUrl || canonicalUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={creatorName || "@TansyAcademy"} />
      <meta name="twitter:title" content={twitterTitle || title} />
      <meta
        name="twitter:description"
        content={twitterDescription || description}
      />
      <meta
        name="twitter:image"
        content={twitterImage || "https://twitter.com/Tansyacademy/photo"}
      />
      <meta
        name="twitter:url"
        content={twitterUrl || "https://twitter.com/Tansyacademy"}
      />
    </Helmet>
  );
}

export function SEOHelmet({ title, description, keywords, currentUrl }) {
  return (
    <Helmet>
      <title>{title || window.location.href}</title>
      <link rel="canonical" href={currentUrl} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}
