import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Form, Input, Button, Select, Space } from "antd";
import { getAdminCourses } from "../../../../redux/courseSlice";
import {
  getCourseLessons,
  updateLessonTitles,
} from "../../../../redux/lessonSlice";
import { CustomSelect } from "../../../../common/select/index.style";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Wrapper } from "../index.style";
import { StyledTable } from "./index.style";

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  editing,
  form,
  ...restProps
}) => {
  useEffect(() => {
    if (editing) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [editing, form, record, dataIndex]);

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={() => record.edit(record)}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const UpdateTopicTitle = () => {
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const initialValuesRef = useRef({});

  const store = useSelector((state) => state);
  const { adminCourses } = store.course;
  const { lessonLoading, courseLessons, updateLoading } = store?.lesson;

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.length) {
      const firstCourseId = adminCourses[0]?._id;
      setSelectedCourse(firstCourseId);
      dispatch(getCourseLessons(firstCourseId));
    }
  }, [adminCourses, dispatch]);

  useEffect(() => {
    if (courseLessons?.length) {
      const data = courseLessons.map((lesson) => ({
        key: lesson?._id,
        id: lesson?._id,
        lessonName: lesson?.name,
        topicSequence: lesson?.sequence || "",
        chapterName: lesson?.course[0]?.chapterName,
        seoTitle: lesson?.title || "",
        interviewShortName: lesson?.interviewShortName || "",
        quizShortName: lesson?.quizShortName || "",
        shortName: lesson?.shortName || "",
        edit: edit,
      }));
      setDataSource(data);
    }
  }, [courseLessons]);

  const courseOptions = adminCourses?.map((course) => ({
    value: course?._id,
    label: course?.longName,
  }));

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    setEditingKey(record.key);
    initialValuesRef.current = record;
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const values = await form.validateFields();
      const updatedRecord = { ...record, ...values };
      if (updatedRecord) {
        const data = {
          name: updatedRecord?.lessonName,
          sequence: updatedRecord?.topicSequence,
          title: updatedRecord?.seoTitle,
          interviewShortName: updatedRecord?.interviewShortName,
          quizShortName: updatedRecord?.quizShortName,
          shortName: updatedRecord?.shortName,
        };
        await dispatch(
          updateLessonTitles({
            data,
            onReset: cancel,
            lessonID: updatedRecord?.id,
            courseID: selectedCourse,
          })
        );
      }
      setEditingKey("");
    } catch (error) {
      console.log("Save failed:", error);
    }
  };

  const columns = [
    {
      title: "Chapter Name",
      dataIndex: "chapterName",
      key: "chapterName",
      editable: false,
    },
    {
      title: "Lesson Name",
      dataIndex: "lessonName",
      key: "lessonName",
      editable: true,
    },
    {
      title: "Topic Sequence",
      dataIndex: "topicSequence",
      key: "topicSequence",
      editable: true,
    },
    {
      title: "SEO Title",
      dataIndex: "seoTitle",
      key: "seoTitle",
      editable: true,
    },
    {
      title: "Lesson Short Name",
      dataIndex: "shortName",
      key: "shortName",
      editable: true,
    },
    {
      title: "Interview Short Name",
      dataIndex: "interviewShortName",
      key: "interviewShortName",
      editable: true,
    },
    {
      title: "Quiz Short Name",
      dataIndex: "quizShortName",
      key: "quizShortName",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size="middle" className="deleteBtn">
            <Button
              loading={updateLoading}
              onClick={() => save(record)}
              disabled={updateLoading}
              style={{
                marginTop: 8,
                marginBlock: 8,
                backgroundColor: "#d39331",
                color: "#fff",
                width: "100px",
              }}
            >
              Save
            </Button>
            <Button
              style={{
                marginTop: 8,
                marginBlock: 8,
                width: "100px",
              }}
              type="primary"
              onClick={cancel}
              disabled={updateLoading}
            >
              Cancel
            </Button>
          </Space>
        ) : (
          <Button
            style={{
              width: "100px",
            }}
            type="primary"
            disabled={editingKey !== "" || updateLoading}
            onClick={() => edit(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        form: form,
      }),
    };
  });

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Update ShortName URLs</title>
      </Helmet>
      <Container fluid>
        <div className="mainHeading">
          <div>
            <h3>Update ShortName URLs</h3>
            <span className="total">{courseLessons?.length} total Topics</span>
          </div>
          <div>
            <CustomSelect>
              <Select
                key={selectedCourse}
                showSearch
                placeholder="-- Select Course --"
                defaultValue={selectedCourse}
                disabled={lessonLoading || updateLoading}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(id, a) => {
                  dispatch(getCourseLessons(id));
                  setSelectedCourse(id);
                }}
                options={courseOptions}
              />
            </CustomSelect>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Form form={form} component={false}>
            <StyledTable
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              loading={lessonLoading}
              dataSource={dataSource}
              columns={mergedColumns}
              rowClassName="editable-row"
              size="small"
              pagination={false}
            />
          </Form>
        </div>
      </Container>
    </Wrapper>
  );
};

export default UpdateTopicTitle;
