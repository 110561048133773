import React from "react";
import refundPolicyData from "./refundPolicyData.json";
import { Container } from "./index.style";
import { MetaHelmet } from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

const RefundPolicy = () => {
  return (
    <Container>
      <MetaHelmet {...metaHelmetData.refund_policy} />
      <h1>Refund Policy</h1>
      {refundPolicyData?.map((section, index) => (
        <section key={index} className="section">
          <h2>{section.title}</h2>
          {Array.isArray(section.content) ? (
            <div className="content">
              {section.content?.map((item, subIndex) => (
                <div key={subIndex}>
                  {typeof item === "string" ? (
                    <p>{item}</p>
                  ) : (
                    <>
                      <h3 className="subtitle">{item.subTitle}:</h3>
                      <ul>
                        {item.items?.map((listItem, listItemIndex) => (
                          <li key={listItemIndex}>{listItem}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="content">
              <p>{section.content}</p>
            </div>
          )}
        </section>
      ))}
    </Container>
  );
};

export default RefundPolicy;
