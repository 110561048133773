import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Image, Progress, Skeleton, Spin, Badge } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CheckOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import {
  quizQuestionsResponse,
  updateQuizResult,
} from "../../../redux/questionsResSlice";
import {
  CustomBtn,
  StyledCheckboxGroup,
  StyledRadioGroup,
  Wrapper,
} from "./index.style";
import { Helmet } from "react-helmet";
import CustomImage from "../../../common/images";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

const QuizQuestionResponse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, quizId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [score, setScore] = useState(0);
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState(null);
  const [isSkeleton, setSkeleton] = useState(false);
  const [lockedFlag, setLockedFlag] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const {
    quizQuestion = [],
    loading,
    updateResult,
    scoreCard,
  } = useSelector((state) => state.questionsRes);
  const action = searchParams.get("status") || false;
  const date = searchParams.get("date") || false;
  const isCompleted =
    ((searchParams.get("isCompletedFlag") &&
      searchParams.get("isCompletedFlag")) === "true" &&
      true) ||
    false;
  const isLockedFlag =
    ((searchParams.get("isLockFlag") && searchParams.get("isLockFlag")) ===
      "true" &&
      true) ||
    false;

  useEffect(() => {
    if (courseId && quizId && (isCompleted || isLockedFlag)) {
      dispatch(quizQuestionsResponse({ action, courseId, quizId, navigate }));
    }
  }, [dispatch, courseId, quizId, isCompleted, isLockedFlag, navigate, action]);

  useEffect(() => {
    setSkeleton(quizQuestion.length === 0);
    setLockedFlag(quizQuestion[0]?.attemptFlag && quizQuestion[0]?.lockedFlag);
  }, [quizQuestion]);

  useEffect(() => {
    if (
      (scoreCard === 200 && updateResult === 200) ||
      isCompleted ||
      isLockedFlag
    ) {
      localStorage.removeItem("quizAnswers");
    }
    if (!action && !date && (!isCompleted || !isLockedFlag)) {
      navigate(`/quiz/response/${courseId}/questions`);
    }
  }, [
    scoreCard,
    navigate,
    updateResult,
    date,
    action,
    courseId,
    isCompleted,
    isLockedFlag,
  ]);

  const questions = useMemo(() => {
    if (quizQuestion && quizQuestion[0]) {
      return (quizQuestion[0]?.Questions || [])?.map((question) => ({
        key: question?._id,
        questionId: question?.questionId,
        questionImageURL: question?.questionImageURL,
        codingQuestion: question?.codingQuestion,
        questionName: question?.questionName,
        explanation: question?.explanation,
        questionType: question?.questionType,
        options: question?.Options?.map((option) => ({
          optionId: option?.optionId,
          answerFlag: option?.answerFlag,
          optionName: option?.optionName,
          studentAnswerFlag: option?.studentAnswerFlag,
        })),
      }));
    }
    return [];
  }, [quizQuestion]);

  const [answers, setAnswers] = useState(Array(questions?.length).fill(""));

  const handleAnswerSelection = (selectedAnswer) => {
    const updatedAnswers = [...answers];
    const currentQuestionObj = {
      question: questions[currentQuestion]?.questionId,
      answer: selectedAnswer,
    };
    if (questions[currentQuestion].questionType === "Checkbox") {
      const selectedOptions = Array.isArray(selectedAnswer)
        ? selectedAnswer
        : [selectedAnswer];
      currentQuestionObj.answer = selectedOptions;
    } else if (
      questions[currentQuestion]?.questionType === "Radio Button" ||
      questions[currentQuestion]?.questionType === "True/False"
    ) {
      currentQuestionObj.answer = selectedAnswer;
    }
    updatedAnswers[currentQuestion] = currentQuestionObj;
    setAnswers(updatedAnswers);
    localStorage.setItem("quizAnswers", JSON.stringify(updatedAnswers));
  };

  const storedAnswers = JSON.parse(localStorage.getItem("quizAnswers")) || [];
  const nonNullValues = storedAnswers?.filter((answer) => answer !== null);
  const nonNullLength = nonNullValues.length;

  const handleNextQuestion = () => {
    const selectedAnswer = answers[currentQuestion]?.answer;
    const correctOptionIds = questions[currentQuestion]?.options
      ?.filter((option) => option?.answerFlag)
      ?.map((option) => option.optionId);

    const isCorrect = Array.isArray(selectedAnswer)
      ? selectedAnswer.every((answer) =>
          correctOptionIds.includes(String(answer))
        )
      : correctOptionIds.includes(String(selectedAnswer));

    setScore((score) => (isCorrect ? score + 1 : score - 0.25));

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
      const storedAnswer = storedAnswers[currentQuestion + 1]?.answer;
      setValue(storedAnswer);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion - 1 >= 0) {
      setCurrentQuestion(currentQuestion - 1);
      const storedAnswer = storedAnswers[currentQuestion - 1]?.answer;
      setValue(storedAnswer);
    }
  };
  // console.log({ quizQuestion });

  const customStyle = {
    ...a11yDark,
    'code[class*="language-"]': {
      ...a11yDark['code[class*="language-"]'],
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      display: "inline-block",
      maxWidth: "100%",
      fontSize: "18px",
      padding: "10px",
      lineHeight: "33px",
    },
    'pre[class*="language-"]': {
      ...a11yDark['pre[class*="language-"]'],
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      display: "inline-block",
      maxWidth: "100%",
      minWidth: "50%",
    },
  };

  const optionsData = useMemo(() => {
    const data = [];
    questions[currentQuestion]?.options?.map((option) => {
      const optionCustomStyle = {
        ...coy,
        'code[class*="language-"]': {
          ...coy['code[class*="language-"]'],
          background: "white",
          color: "black",
          wordWrap: "break-word",
          whiteSpace: "pre-wrap",
        },
        'code[class*="language-"] .token': {
          ...coy['code[class*="language-"] .token'],
          color: "#007ACC",
        },
        'pre[class*="language-"]': {
          ...coy['pre[class*="language-"]'],
          background: "white",
          color: "black",
          border:
            lockedFlag && option?.answerFlag && option?.studentAnswerFlag
              ? "double #0ded23"
              : lockedFlag && !option?.answerFlag && option?.studentAnswerFlag
              ? "1px solid #ed410d"
              : lockedFlag && option?.answerFlag && !option?.studentAnswerFlag
              ? "dotted #558c8d"
              : "",
          borderRadius: "5px",
          wordWrap: "break-word",
          whiteSpace: "pre-wrap",
        },
      };

      data.push({
        label: (
          <>
            {lockedFlag && option?.optionName.includes("\n") ? (
              <>
                <SyntaxHighlighter
                  language="sql"
                  wrapLongLines={true}
                  style={optionCustomStyle}
                >
                  {option?.optionName}
                </SyntaxHighlighter>
              </>
            ) : (
              <>
                {option?.optionName.toString()}
                {lockedFlag &&
                  option?.answerFlag &&
                  option?.studentAnswerFlag && (
                    <span>
                      <CheckOutlined
                        style={{ fontSize: "7px", marginLeft: "4px" }}
                      />
                      <CheckOutlined
                        style={{ fontSize: "7px", marginLeft: "0px" }}
                      />
                    </span>
                  )}
                {lockedFlag &&
                  !option?.answerFlag &&
                  option?.studentAnswerFlag && (
                    <span>
                      <CloseOutlined
                        style={{ fontSize: "7px", marginLeft: "4px" }}
                      />
                    </span>
                  )}
                {lockedFlag &&
                  option?.answerFlag &&
                  !option?.studentAnswerFlag && (
                    <span>
                      <CheckOutlined
                        style={{ fontSize: "7px", marginLeft: "4px" }}
                      />
                    </span>
                  )}{" "}
              </>
            )}
          </>
        ),

        value: option?.optionId,
        optionName: option?.optionName.toString(),
        answerFlag: option?.answerFlag,
        studentAnswerFlag: option?.studentAnswerFlag,
        style: {
          display: "flex",
          color: lockedFlag
            ? option.answerFlag
              ? "green"
              : option.studentAnswerFlag
              ? "red"
              : "black"
            : "black",
          padding: "16px 0",
        },
      });
    });
    return data;
  }, [questions, currentQuestion, lockedFlag]);

  const selectedCheckedValues = useMemo(
    () =>
      optionsData
        ?.filter((option) => option.answerFlag)
        ?.map((option) => option.value),
    [optionsData]
  );

  const onChange = (value) => setValue(value);

  const selectedRadioValues = useMemo(
    () =>
      optionsData
        ?.filter((option) => option.answerFlag)
        ?.map((option) => option.value),
    [optionsData]
  );

  const totalQuestions = quizQuestion[0]?.Questions?.length || 0;

  const isLastQuestion = currentQuestion === totalQuestions - 1;
  const isFirstQuestion = currentQuestion === 0;
  const correctAnswers = optionsData
    ?.filter((option) => option.answerFlag)
    ?.map((option) => option.optionName);

  const studentCorrectAnswers = optionsData
    ?.filter((option) => option.studentAnswerFlag)
    ?.map((option) => option.optionName);

  const isCorrect = optionsData?.every(
    (option) => option.studentAnswerFlag === option.answerFlag
  );

  const feedbackMessage = (
    <Badge.Ribbon text="Results" color="purple">
      <div
        style={{
          gap: "20px",
          alignItems: "start",
          textAlign: "start",
          minWidth: "50px",
          padding: "10px 20px",
          backgroundColor: isCorrect ? "#d4edda" : "#f8d7da",
          margin: "10px 0 10px 0",
          border: "1px solid #ccc",
          borderRadius: "10px",
          overflow: "auto",
        }}
      >
        <span style={{ marginBottom: "10px" }}>
          <Badge
            status={isCorrect ? "success" : "error"}
            text={
              <>
                Your answer
                {studentCorrectAnswers?.length === 1 ? " is" : " are"} :{" "}
                <span
                  style={{
                    fontWeight: 700,
                    color: isCorrect ? "#429656" : "#964a42",
                  }}
                >
                  {isCorrect ? " RIGHT" : " WRONG"}
                </span>
              </>
            }
          />
        </span>

        {!isCorrect && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              gap: "20px",
              alignItems: "start",
              textAlign: "center",
              margin: "10px 0 ",
              minWidth: "50px",
              overflow: "auto",
            }}
          >
            <div style={{ textAlign: "left", paddingRight: "10px" }}>
              <p style={{ fontWeight: 700, textDecoration: "underline" }}>
                Your{" "}
                {studentCorrectAnswers?.length === 1 ? "answer" : "answers"}:
              </p>
              <p>"{studentCorrectAnswers?.join(", ")}"</p>
            </div>

            <div
              style={{
                position: "relative",
                padding: "0 20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              {" "}
            </div>

            <div style={{ textAlign: "left", paddingLeft: "10px" }}>
              <p
                style={{
                  fontWeight: 700,
                  textDecoration: "underline",
                  color: "#429656",
                }}
              >
                The correct{" "}
                {correctAnswers?.length === 1 ? "answer is" : "answers are"}:
              </p>
              <div>
                {correctAnswers?.map((answer, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: "#080808",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      "{answer}"
                    </span>
                    {index < correctAnswers.length - 1 && (
                      <div style={{ borderBottom: "2px solid #f9f5f5" }} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Badge.Ribbon>
  );

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy | Quiz Test</title>
      </Helmet>
      <Spin spinning={loading}>
        <Container className="Container" fluid>
          <Skeleton active loading={isSkeleton}>
            <h3 className="Heading">
              <span className="HeadingText">
                {quizQuestion &&
                  `${
                    quizQuestion[0]?.quizId?.lessonId?.course?.[0]
                      ?.chapterName || "Chapter Name Not Available"
                  } - ${
                    quizQuestion[0]?.quizId?.lessonId?.name ||
                    "Lesson Name Not Available"
                  }`}
              </span>
              <br />
            </h3>
            <div className="mainHeading">
              <div className="CompletedSection">
                <h3 className="CompletedText">
                  {`Completed (${
                    !lockedFlag ? nonNullLength : totalQuestions
                  }/${totalQuestions})`}
                </h3>
                <Progress
                  percent={
                    ((!lockedFlag ? nonNullLength : totalQuestions) /
                      totalQuestions) *
                    100
                  }
                  status="active"
                  strokeColor="#D39331"
                  size={[300, 20]}
                  strokeWidth={3}
                  showInfo={false}
                />
              </div>

              {!lockedFlag ? (
                <CustomBtn
                  onClick={async () => {
                    try {
                      if (storedAnswers.length > 0) {
                        await dispatch(
                          updateQuizResult({
                            courseId,
                            quizId,
                            navigate,
                            data: { result: storedAnswers },
                          })
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  disabled={
                    loading ||
                    totalQuestions !== answers.length ||
                    totalQuestions !== nonNullLength
                  }
                >
                  {loading ? (
                    <LoadingOutlined className="loader" />
                  ) : (
                    "Submit entire quiz for grading"
                  )}
                </CustomBtn>
              ) : (
                `Total Score : ${quizQuestion[0]?.totalScore}/ ${
                  totalQuestions * 1
                }`
              )}
            </div>
            {questions[currentQuestion]?.questionImageURL && (
              <Image
                className="imageContainer"
                rounded
                style={{
                  minWidth: 300,
                  maxWidth: 700,
                  minHeight: 200,
                  maxHeight: 600,
                }}
                preview={false}
                src={questions[currentQuestion]?.questionImageURL}
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            )}
            <h3 className="question">
              <span style={{ whiteSpace: "pre-line" }}>
                {`${currentQuestion + 1}. ${
                  questions[currentQuestion]?.questionName
                }`}
              </span>
            </h3>
            {questions[currentQuestion]?.codingQuestion && (
              <div style={{ marginLeft: "15px" }}>
                <SyntaxHighlighter
                  language="sql"
                  wrapLongLines={true}
                  style={customStyle}
                >
                  {questions[currentQuestion]?.codingQuestion}
                </SyntaxHighlighter>
              </div>
            )}
            {questions[currentQuestion]?.questionType === "Checkbox" && (
              <div>
                <em>
                  Note: This is a multiple-answer question. You can select one
                  or more options.
                </em>
              </div>
            )}
            <div>
              {(() => {
                switch (questions[currentQuestion]?.questionType) {
                  case "Radio Button":
                  case "True/False":
                    return (
                      <StyledRadioGroup
                        name="answer"
                        key={optionsData.optionId}
                        options={optionsData}
                        className="pt-2 pb-2"
                        value={lockedFlag ? selectedRadioValues[0] : value}
                        onChange={({ target: { value } }) => {
                          if (!lockedFlag) {
                            onChange(value);
                            handleAnswerSelection(value);
                          }
                        }}
                        lockedflag={lockedFlag ? "true" : ""}
                      />
                    );
                  case "Checkbox":
                    return (
                      <StyledCheckboxGroup
                        name="answer"
                        key={optionsData.optionId}
                        options={optionsData}
                        className="pt-2 pb-2"
                        value={lockedFlag ? selectedCheckedValues : value}
                        onChange={(checkedValues) => {
                          if (!lockedFlag) {
                            onChange(checkedValues);
                            handleAnswerSelection(checkedValues);
                          }
                        }}
                        lockedflag={lockedFlag ? "true" : ""}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>

            {lockedFlag && <div>{feedbackMessage}</div>}
            {lockedFlag && (
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  margin: "10px 0px 10px 0",
                  padding: "10px",
                  borderRadius: "10px",
                  borderColor: "#c3e6cb",
                  color: "#9fd1a8",
                  backgroundColor: "#6c757d",
                }}
              >
                <strong
                  style={{ marginRight: "5px", textDecoration: "underline" }}
                >
                  Explanation:{" "}
                </strong>
                <p style={{ marginLeft: "1.5em", margin: 0 }}>
                  <span style={{ whiteSpace: "pre-line", color: "#d4edda" }}>
                    {questions[currentQuestion]?.explanation}
                  </span>
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                alignItems: "center",
              }}
            >
              <div
                onClick={handlePreviousQuestion}
                style={{
                  marginRight: "20px",
                  transform: "rotate(180deg)",
                  cursor: isFirstQuestion ? "not-allowed" : "pointer",
                  opacity: isFirstQuestion ? 0.5 : 1,
                  pointerEvents: isFirstQuestion ? "none" : "auto",
                }}
              >
                <CustomImage
                  src="/icons/right-arrow-quiz.svg"
                  alt="i"
                  width="100%"
                  height="100%"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
              <div
                onClick={handleNextQuestion}
                style={{
                  cursor: isLastQuestion ? "not-allowed" : "pointer",
                  opacity: isLastQuestion ? 0.5 : 1,
                  pointerEvents: isLastQuestion ? "none" : "auto",
                }}
              >
                <CustomImage
                  src="/icons/right-arrow-quiz.svg"
                  alt="i"
                  width="100%"
                  height="100%"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
            </div>
            <div className="questionCount">{`Question (${
              currentQuestion + 1
            }/${totalQuestions})`}</div>
          </Skeleton>
        </Container>
      </Spin>
    </Wrapper>
  );
};

export default QuizQuestionResponse;
