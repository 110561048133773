import { Button } from "antd";
import styled from "styled-components";

export const QuizContainer = styled.div`
  font-family: Source Sans Pro, sans-serif;
  background-color: #ffffff;
  margin: 2em auto;
  padding: 2em 0;
  max-width: 90%;

  .mainHeading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h3,
    .heading,
    .header {
      text-transform: uppercase;
      color: #313131;
      font-weight: 600;
      font-size: 22px;
      text-align: start;
    }

    .header {
      font-weight: 400;
    }

    .total {
      font-weight: 400;
      color: #31313160;
      font-size: 16px;
    }

    .ant-form-item {
      width: 350px;
    }
  }

  h1 {
    text-align: center;
    color: #333;
  }

  h5 {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
  }

  .subscriptionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem 1rem 0;
    margin: 0.5rem 0;

    .subscriptionBox {
      background-color: #d393312b;
      padding: 0rem 2rem 0.5rem 1rem;
      display: flex;
      justify-content: start;
      gap: 1rem;

      p {
        color: #d39331;
        padding-top: 0.4rem;
      }

      .subscriptionIcon {
        color: #d39331;
        font-size: 1.25rem;
        margin-top: 0.6rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`;

export const PublicQuizContainer = styled.div`
  font-family: Source Sans Pro, sans-serif;
  background-color: #ffffff;
  margin: 1em auto;
  padding: 2em 0;
  max-width: 90%;
  .mainHeading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ant-typography {
    font-size: 16px;
    margin-bottom: 16px;
    strong {
      font-weight: bold;
    }
  }
  .title {
    font-size: 38px;
    margin-bottom: 2.5em;
    strong {
      font-weight: bold;
    }
  }
  .explanationTextContent {
    margin: 10px 0px 10px 10px;
    padding: 10px;
    border-color: #c3e6cb;
    color: #d4edda;
    background-color: #6c757d;
  }
  .subscriptionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem 1rem 0;
    margin: 0.5rem 0;

    .subscriptionBox {
      background-color: #d393312b;
      padding: 0rem 2rem 0.5rem 1rem;
      display: flex;
      justify-content: start;
      gap: 1rem;

      p {
        color: #d39331;
        padding-top: 0.4rem;
      }

      .subscriptionIcon {
        color: #d39331;
        font-size: 1.25rem;
        margin-top: 0.6rem;
      }
    }
  }
  .correct {
    margin-left: 10px;
    padding: 10px;
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }

  .incorrect {
    margin-left: 10px;
    padding: 10px;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }

  hr {
    border: none;
    border-top: 2px solid #ccc;
  }
  .answer {
    border-radius: 5px;
    transition: background-color 0.3s ease, border-color 0.3s ease,
      color 0.3s ease;
  }
  .explanation {
    border-radius: 5px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .question-container {
    margin-bottom: 20px;
  }

  .ant-form-item {
    width: 100%;
  }

  .ant-radio-group,
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 16px;
      width: 100%;

      .ant-radio,
      .ant-checkbox {
        margin-right: 5px;
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;
        text-align: left;
      }
    }
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      flex-direction: row;
    }
  }

  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 600px) {
    .title {
      font-size: 28px;
      margin-bottom: 1.5em;
      strong {
        font-weight: bold;
      }
    }
    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
      flex-direction: column;
      align-items: flex-start;

      .ant-radio,
      .ant-checkbox {
        margin-bottom: 8px;
      }
    }
  }
`;

export const CustomButton = styled(Button)`
  background: #d39331 !important;
  border: 1px solid #d39331 !important;
  width: 193px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;

  .loader {
    font-size: 2rem !important;
  }

  &:disabled {
    background: #d39331;
    border: 1px solid #d39331;
    width: 193px;
    height: 42px;
    border-radius: 0;
    opacity: 0.5;

    &:hover {
      background: #d39331;
      border: 1px solid #d39331;
      border-radius: 0;
      opacity: 0.5;
    }
  }

  span {
    font-size: 16px;
    color: #ffffff;
  }

  &:focus,
  &:hover {
    background: #d39331;
    border-radius: 0;
    border: 1px solid #d39331;
    opacity: 0.9;

    span {
      color: #ffffff;
    }
  }
`;
