import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";

export const submitUrls = createAsyncThunk(
  "submitUrls/indexNow",
  async (urlList, { rejectWithValue }) => {
    try {
      const response = await ApiRequests.submitUrls(urlList);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to submit URLs. Please try again later.";
      return rejectWithValue({
        status: error.response?.status || 500,
        message: errorMessage,
      });
    }
  }
);

const initialState = {
  loading: false,
  submittedUrlStatus: null,
  error: null,
};

const urlSlice = createSlice({
  name: "submitUrls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitUrls.pending, (state) => {
        state.loading = true;
        state.submittedUrlStatus = null;
        state.error = null;
      })
      .addCase(submitUrls.fulfilled, (state, action) => {
        state.loading = false;
        state.submittedUrlStatus = action.payload;
        state.error = null;
      })
      .addCase(submitUrls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default urlSlice.reducer;
