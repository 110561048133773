import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Spin, Form, Radio, Checkbox, Typography, Image } from "antd";
import { BackBtn } from "../Forum/forumList/threadQuestion/index.style";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getPublicQuizQuestions } from "../../redux/questionsSlice";
import { CustomButton, PublicQuizContainer } from "./index.style";
import { shuffleArray } from "../../utils/helper";
import { Container } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { InfoCircleFilled } from "@ant-design/icons";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import { SEOHelmet } from "../../common/helmet";

const { Title, Paragraph } = Typography;

const QuizComponent = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const courseName = searchParams.get("course");
  const quizShortName = searchParams.get("topic");
  const [seoKeywords, setSEOKeywords] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [scoreSection, setScoreSection] = useState("");
  const [questions, setQuestions] = useState([]);
  const [titleData, setTitleData] = useState(null);
  const [enrolled, setEnrolled] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [unansweredQuestions, setUnansweredQuestions] = useState({
    isFlag: true,
    count: 0,
  });
  const { userProfile } = useSelector((state) => state?.auth);
  const { loadingQuestions, publicQuizQuestions } = useSelector(
    (state) => state?.questions
  );

  useEffect(() => {
    if (courseName && quizShortName) {
      dispatch(getPublicQuizQuestions({ courseName, quizShortName }));
    }
  }, [dispatch, courseName, quizShortName]);

  useEffect(() => {
    const checkEnrollmentAndSubscription = () => {
      if (!userProfile || !userProfile.subscription) {
        setEnrolled(false);
        return;
      }
      const subscriptionForSelectedCourse =
        userProfile?.subscription?.quizAccess?.find(
          (access) => access?.courseId === selectedCourse
        );
      if (!subscriptionForSelectedCourse) {
        setEnrolled(false);
        return;
      }
      const expiryDate = moment(subscriptionForSelectedCourse?.expiryDate);
      const isSubscriptionValid = expiryDate.isAfter(moment(), "day");
      const differenceInDays = expiryDate.diff(moment(), "days");
      setEnrolled(isSubscriptionValid || differenceInDays >= 0);
    };
    checkEnrollmentAndSubscription();
  }, [selectedCourse, userProfile]);

  useEffect(() => {
    if (publicQuizQuestions) {
      setSelectedCourse(publicQuizQuestions?.courseId);
      const {
        Questions = [],
        courseName = "",
        chapterName = "",
        lessonUrl = "#",
        lessonName = "",
        quizDescription = "",
        quizKeywords = "",
        quizTitle = "",
        quizQuestionCount = 0,
      } = publicQuizQuestions;
      const url = window.location.href;
      const shuffledQuestions = shuffleArray(Questions);
      const questionsWithShuffledOptions = shuffledQuestions?.map(
        (question) => ({
          ...question,
          options: shuffleArray(question?.options || []),
        })
      );
      setQuestions(questionsWithShuffledOptions);
      setSEOKeywords({
        pageTitle: quizTitle,
        pageDescription: quizDescription,
        pageKeywords: quizKeywords,
        pageUrl: url,
      });

      setTitleData({
        seo:
          courseName && lessonName
            ? `${courseName?.toUpperCase()} | ${lessonName?.toUpperCase()} QUIZ QUESTIONS`
            : "",
        title: `${courseName} Database Quiz Questions`,
        course: (
          <Link to={`/course_detail?name=${courseName}`}>{courseName}</Link>
        ),
        chapter: chapterName,
        lesson: <Link to={lessonUrl}>{lessonName}</Link>,
        questionsCount: quizQuestionCount,
      });
    }
  }, [publicQuizQuestions]);

  const getOptionLabels = (ids, options) => {
    const arrayIds = Array.isArray(ids) ? ids : [ids];
    return arrayIds
      ?.map(
        (id) =>
          options?.find((option) => String(option?._id) === String(id))
            ?.optionName
      )
      ?.filter(Boolean);
  };
  const onValuesChange = (values) => {
    const formValues = form.getFieldsValue();
    const allValues = Object.values(formValues);
    const answeredValues = allValues.filter((value) => value !== undefined);
    const allQuestionsAnswered = questions.length === answeredValues.length;
    const unansweredCount = questions.length - answeredValues.length;

    setUnansweredQuestions((prevState) => ({
      ...prevState,
      isFlag: allQuestionsAnswered,
      count: unansweredCount,
    }));
  };
  const onFinish = (values) => {
    if (submitted) return;
    let score = 0;
    questions?.forEach(
      (
        {
          questionType = "",
          correctAnswer = [],
          options = [],
          explanation = "",
        },
        index
      ) => {
        const userAnswers = values[`question${index + 1}`] || [];
        const answerDiv = document.getElementById(`answer${index + 1}`);
        const explanationDiv = document.getElementById(
          `explanation${index + 1}`
        );

        const userAnswerLabels = getOptionLabels(userAnswers, options);
        const correctAnswerLabels = getOptionLabels(correctAnswer, options);

        if (questionType === "True/False" || questionType === "Radio Button") {
          const isCorrect = String(userAnswers) === String(correctAnswer[0]);
          score += isCorrect ? 1 : 0;
          answerDiv.innerHTML = `
  <p><strong>YOUR ANSWER : ${isCorrect ? "CORRECT" : "WRONG"}</strong></p>
  <p>
    <b>Selected answer: </b> ${userAnswerLabels?.join(", ")} 
    ${
      isCorrect
        ? " (Correct!)"
        : ` is incorrect. <b>Correct answer:</b> "${correctAnswerLabels?.join(
            ", "
          )}".`
    }
  </p>
`;

          answerDiv.classList.add(isCorrect ? "correct" : "incorrect");
        } else if (questionType === "Checkbox") {
          const isCorrect =
            userAnswers?.length === correctAnswer?.length &&
            userAnswers?.every((value, idx) => value === correctAnswer[idx]);
          score += isCorrect ? 1 : 0;
          answerDiv.innerHTML = `
  <p><strong>YOUR ANSWER : ${isCorrect ? "CORRECT" : "WRONG"}</strong></p>
  <p>
     <b>Selected answer: </b> ${userAnswerLabels?.join(", ")}
    ${
      isCorrect
        ? " (Correct!)"
        : ` are incorrect. <b>Correct answer:</b>  "${correctAnswerLabels?.join(
            ", "
          )}".`
    }
  </p>
`;
          answerDiv?.classList?.add(isCorrect ? "correct" : "incorrect");
        }

        explanationDiv.textContent = `Explanation: ${explanation || "N/A"}`;
        explanationDiv?.classList?.add("explanationTextContent");
      }
    );

    setSubmitted(true);
    setScoreSection(`Your Score: ${score}/${questions.length}`);
  };

  const customStyle = {
    ...a11yDark,
    'code[class*="language-"]': {
      ...a11yDark['code[class*="language-"]'],
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      display: "inline-block",
      maxWidth: "100%",
      fontSize: "16px",
      padding: "10px",
      lineHeight: "30px",
    },
    'pre[class*="language-"]': {
      ...a11yDark['pre[class*="language-"]'],
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      display: "inline-block",
      maxWidth: "100%",
      minWidth: "50%",
    },
  };
  const optionCustomStyle = {
    ...coy,
    'code[class*="language-"]': {
      ...coy['code[class*="language-"]'],
      background: "white",
      color: "black",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    'code[class*="language-"] .token': {
      ...coy['code[class*="language-"] .token'],
      color: "#007ACC",
    },
    'pre[class*="language-"]': {
      ...coy['pre[class*="language-"]'],
      background: "white",
      color: "black",
      // padding: "1em",
      // border: "1px solid #ddd",
      // borderRadius: "5px",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
  };

  const reactMarkdownStyles = {
    h1: {
      fontSize: "32px",
      color: "#333",
      margin: "10px 0",
    },
    h2: {
      fontSize: "24px",
      color: "#555",
      margin: "8px 0",
    },
    p: {
      // fontSize: "17px",
      lineHeight: "1.8",
      color: "#777",
      margin: "2px 0",
    },
    li: {
      fontSize: "16px",
      lineHeight: "1.6",
      margin: "10px 0",
    },
    codeBlock: {
      backgroundColor: "white",
      padding: "1px",
      borderRadius: "5px",
      overflowX: "auto",
      color: "#f5f5f5",
      margin: "1px 0",
    },
    codeInline: {
      backgroundColor: "#f5f5f5",
      padding: "2px 5px",
      borderRadius: "3px",
      fontSize: "16px",
    },
  };

  return (
    <PublicQuizContainer>
      <Spin spinning={loadingQuestions}>
        <SEOHelmet
          title={seoKeywords.pageTitle}
          description={seoKeywords.pageDescription}
          keywords={seoKeywords.pageKeywords}
          currentUrl={seoKeywords.pageUrl}
        />
        <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <Container>
            <Title level={1} className="title">
              <center>{titleData?.title}</center>
            </Title>
            <div className="mainHeading">
              <div>
                <Paragraph className="ant-typography">
                  Course Name:&nbsp;&nbsp;&nbsp;{" "}
                  <strong>{titleData?.course}</strong>
                </Paragraph>
                <Paragraph className="ant-typography">
                  Chapter Name: &nbsp;&nbsp;&nbsp;
                  <strong>{titleData?.chapter}</strong>
                </Paragraph>
                <Title level={1} className="ant-typography">
                  Lesson Content Link: &nbsp;&nbsp;&nbsp;
                  <strong>{titleData?.lesson}</strong>
                </Title>
                <Paragraph className="ant-typography">
                  Current Quiz Count:&nbsp;&nbsp;&nbsp;
                  <strong style={{ color: "#00AC47" }}>
                    {titleData?.questionsCount}
                  </strong>
                </Paragraph>
                <>
                  <div className="subscriptionWrapper">
                    <div className="subscriptionBox">
                      <InfoCircleFilled className="subscriptionIcon" />
                      {Object.keys(userProfile).length > 0 ? (
                        !enrolled ? (
                          <p>
                            Subscribe to receive additional questions and
                            enhance your learning experience.&nbsp;
                            <Link
                              to="/learn_sql_power_bi_analytics_online_training_cost"
                              style={{ textDecoration: "underline" }}
                            >
                              click here
                            </Link>
                          </p>
                        ) : (
                          <p>Enroll to monitor your progress</p>
                        )
                      ) : (
                        <p>
                          To gain complete access, login with gmail or outlook
                          &nbsp;
                          <Link
                            to="/login"
                            style={{ textDecoration: "underline" }}
                          >
                            click here
                          </Link>
                        </p>
                      )}
                    </div>
                  </div>
                </>

                <BackBtn
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <BsChevronLeft />
                  Go back
                </BackBtn>
              </div>
              <div className="mb-3">
                <center>
                  <CustomButton
                    id="submitButton"
                    type="primary"
                    htmlType="submit"
                    disabled={submitted || !Object.keys(userProfile).length}
                  >
                    {submitted ? scoreSection : "Submit Answers"}
                  </CustomButton>
                </center>
                {!submitted && (
                  <center style={{ padding: "5px 0" }}>
                    Note:{" "}
                    {Object.keys(userProfile).length > 0 ? (
                      unansweredQuestions?.isFlag ? (
                        <span style={{ color: "#ccc" }}>
                          Please answer all questions
                          <br /> before submitting.
                        </span>
                      ) : (
                        <span style={{ color: "red", margin: "10px 0" }}>
                          {unansweredQuestions.count} question(s) unanswered.
                        </span>
                      )
                    ) : (
                      <span style={{ color: "#f90c0c", margin: "10px 0" }}>
                        Please login to submit your answers.
                      </span>
                    )}
                  </center>
                )}
              </div>
            </div>

            {questions?.map(
              (
                {
                  questionName = "",
                  codingQuestion = null,
                  questionImageURL = null,
                  questionType = "",
                  options = [],
                },
                index
              ) => (
                <div key={index} className="question-container">
                  <Title level={5} className="ant-typography">
                    {index + 1}.{" "}
                    <span
                      style={{
                        whiteSpace: "pre-wrap",
                        display: "inline",
                        verticalAlign: "top",
                      }}
                    >
                      {questionName}
                    </span>
                  </Title>

                  {/* {codingQuestion && codingQuestion?.includes("```sql") ? (
                    <div style={{ marginLeft: "15px" }}>
                      <ReactMarkdown
                        children={codingQuestion}
                        components={{
                          h1: ({ children }) => (
                            <h1 style={reactMarkdownStyles.h1}>{children}</h1>
                          ),
                          h2: ({ children }) => (
                            <h2 style={reactMarkdownStyles.h2}>{children}</h2>
                          ),
                          p: ({ children }) => (
                            <p style={reactMarkdownStyles.p}>{children}</p>
                          ),
                          li: ({ children }) => (
                            <p style={reactMarkdownStyles.li}>{children}</p>
                          ),
                          code({ children, className = "", ...rest }) {
                            const match = className?.match(/language-(\w+)/);
                            return match ? (
                              <SyntaxHighlighter
                                {...rest}
                                language={match[1]}
                                style={customStyle}
                                wrapLongLines
                              >
                                {String(children)?.trim()}
                              </SyntaxHighlighter>
                            ) : (
                              <code {...rest}>{children}</code>
                            );
                          },
                        }}
                      />
                    </div>
                  ) : (
                    codingQuestion && (
                      <SyntaxHighlighter
                        language="sql"
                        style={customStyle}
                        wrapLongLines
                      >
                        {codingQuestion}
                      </SyntaxHighlighter>
                    )
                  )} */}
                  {codingQuestion && (
                    <div style={{ marginLeft: "15px" }}>
                      <SyntaxHighlighter
                        language="sql"
                        wrapLongLines={true}
                        style={customStyle}
                      >
                        {codingQuestion}
                      </SyntaxHighlighter>
                    </div>
                  )}
                  {questionImageURL && (
                    <Image
                      src={questionImageURL}
                      alt={`Question Image ${index}`}
                      style={{
                        minWidth: 300,
                        maxWidth: 700,
                        minHeight: 200,
                        maxHeight: 600,
                      }}
                      preview={false}
                      placeholder={
                        <Image
                          preview={false}
                          src="https://tansyacademy.com/logo.svg"
                          alt="Image"
                        />
                      }
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                  )}
                  <Form.Item
                    name={`question${index + 1}`}
                    rules={[
                      {
                        required: true,
                        message: (
                          <p style={{ color: "red", margin: "10px 0" }}>
                            {"Please select at least one option!"}
                          </p>
                        ),
                      },
                    ]}
                  >
                    {questionType === "Checkbox" ? (
                      <Checkbox.Group
                        options={options?.map(({ _id, optionName }) => ({
                          value: _id,
                          label: optionName.includes("\n") ? (
                            <SyntaxHighlighter
                              language="sql"
                              wrapLongLines={true}
                              style={optionCustomStyle}
                            >
                              {optionName}
                            </SyntaxHighlighter>
                          ) : (
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {optionName}
                            </p>
                          ),
                        }))}
                        disabled={submitted}
                      />
                    ) : (
                      <Radio.Group
                        options={options?.map(({ _id, optionName }) => ({
                          value: _id,
                          label: (
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {optionName}
                            </p>
                          ),
                        }))}
                        buttonStyle="solid"
                        disabled={submitted}
                      />
                    )}
                  </Form.Item>
                  <Paragraph
                    className="answerStatus ant-typography"
                    id={`answerStatus${index + 1}`}
                  />
                  <Paragraph
                    className="answer ant-typography"
                    id={`answer${index + 1}`}
                  />
                  <Paragraph
                    className="explanation ant-typography"
                    id={`explanation${index + 1}`}
                  />
                </div>
              )
            )}
          </Container>
        </Form>
      </Spin>
    </PublicQuizContainer>
  );
};

export default QuizComponent;
