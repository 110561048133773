import React from "react";
import { Card, Col, Row, Image, Typography, Spin } from "antd";
import { Link } from "react-router-dom";
import sqlProjectsData from "./projectsData.json";
import { Container } from "react-bootstrap";
import { Marquee } from "../../common/sitemap/index.style";
import { Wrapper } from "./index.style";

const { Title, Paragraph } = Typography;

const SQLProjects = () => {
  const [loading, setLoading] = React.useState(true);
    const handleImageLoad = () => {
      setLoading(false);
    };

    const handleImageError = () => {
      setLoading(false);
    };
  return (
    <Container>
      <Wrapper>
        <Title level={1} className="heading">
          {sqlProjectsData.category}
        </Title>
        <Marquee className="marquee" style={{ color: "#666" }}>
          Join our Zoom session, where we’ll provide guidance for successfully
          completing these SQL assignments.{" "}
          <Link to="/learn_sql_class_online_sql_training_course">Join now</Link>
        </Marquee>
        <ul style={{ marginBottom: "30px" }}>
          <li style={{ marginBottom: "20px" }}>
            {sqlProjectsData.description}
          </li>
          <li>{sqlProjectsData.business_analysis.description}</li>
        </ul>

        <Row gutter={[0, 16]}>
          {sqlProjectsData.projects.map((project, index) => (
            <React.Fragment key={index}>
              <Title level={4} className="project-title">
                {project.title}
              </Title>
              <Col xs={24} className="centered-col">
                <Card className="styled-card" hoverable>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} lg={8} className="centered">
                      <div
                        className="image-container"
                        style={{
                          position: "relative",
                          width: "350px",
                          height: "230px",
                        }}
                      >
                        {loading && (
                          <div
                            className="spinner-container"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Spin size="large" />
                          </div>
                        )}
                        <Image
                          alt={project.title}
                          src={project.image_url}
                          className="image"
                          width="350px"
                          height="230px"
                          preview={false}
                          onLoad={handleImageLoad}
                          onError={handleImageError}
                          style={{ display: loading ? "none" : "block" }}
                        />
                      </div>
                    </Col>
                    <Col xs={24} lg={10}>
                      <Paragraph className="scrollable-description title-paragraph">
                        {project.title}
                      </Paragraph>
                      <div className="scrollable-description description">
                        <Paragraph align="start">
                          <ul style={{ marginTop: "5px" }}>
                            {project.description.map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        </Paragraph>
                      </div>
                    </Col>

                    <Col xs={24} lg={6} className="centered-col">
                      <Paragraph className="scrollable-description title-paragraph">
                        {project.assignmentsTitle}
                      </Paragraph>
                      <div className="scrollable-description description">
                        <Paragraph align="start">
                          <ol style={{ marginTop: "5px" }}>
                            {project.assignments.map((assignment, index) => (
                              <Link key={index} to={assignment.url}>
                                <li
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {assignment.name}
                                </li>
                              </Link>
                            ))}
                          </ol>
                        </Paragraph>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </Wrapper>
    </Container>
  );
};

export default SQLProjects;
