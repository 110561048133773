import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";
import { getTransactions } from "./paymentSlice";
import { enrollCourse } from "./courseSlice";

export const processPayment = createAsyncThunk(
  "stripe/processPayment",
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.processPayment(data);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const successfulPaymentStorage = createAsyncThunk(
  "stripe/successfulPaymentStorage",
  async (sessionId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.sessionStorage(sessionId);
      if (response.status === 200) {
        dispatch(sendReceipt(response?.data?.data?.id));
        return response?.data?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const sendReceipt = createAsyncThunk(
  "stripe/sendReceipt",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.sendReceipt(id);
      if (response.status === 201) {
        successMsg("Transaction PDF sent to your email.");
        return response?.data?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const sessionMemory = createAsyncThunk(
  "stripe/PostSessionEntries",
  async (sessionId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.sessionMemory(sessionId);
      if (response.status === 200) {
        await dispatch(sendReceipt(response?.data?.data?.id));
        await dispatch(getTransactions(sessionId.action));
        successMsg(response?.data?.Status);
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg(error?.response?.data?.error);
      throw error;
    }
  }
);

export const getRazorPay = createAsyncThunk(
  "stripe/getRazorPay",
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getRazorPay(data);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  sessionStorage: {},
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(processPayment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(processPayment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRazorPay.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRazorPay.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getRazorPay.rejected, (state) => {
        state.loading = false;
      })
      .addCase(successfulPaymentStorage.pending, (state) => {
        state.loading = true;
      })
      .addCase(successfulPaymentStorage.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionStorage = action.payload;
      })
      .addCase(successfulPaymentStorage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(sessionMemory.pending, (state) => {
        state.loading = true;
      })
      .addCase(sessionMemory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sessionMemory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(sendReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendReceipt.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendReceipt.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default stripeSlice.reducer;
