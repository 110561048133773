import React from "react";
import { Container } from "./index.style";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomBtn from "../../common/button";
import { Link } from "react-router-dom";
import { MetaHelmet } from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";
import { Image } from "antd";

const Internship = () => {
  const statisticsData = [
    { title: "Back-end Developers", internships: 5 },
    { title: "Full-stack Developers", internships: 5 },
    { title: "SQL Data Analysis", internships: 5 },
    { title: "SQL Programming", internships: 5 },
  ];

  const codingExperienceData = [
    "An exceptional chance for students to engage as UI developers, back - end developers, full - stack developers, database programmers, and data analysts.",
    <>
      Join us and become a member of our innovative SaaS product,{" "}
      <Link to="https://tansycloud.com" target="_blank">
        www.tansycloud.com
      </Link>
      .
    </>,
    "With our live Zoom classes, mentorship, and well-structured internship, breaking barriers should become effortless for you.",
    "You'll have your dedicated live web application where you can implement and test your code changes directly.",
    "We utilize a tech stack comprising React for the UI, Python and Node.js for the backend, along with Power BI, MySQL, MongoDB, and Azure Cloud",
  ];

  const appDeveloperData = [
    "Ideal for technology and software development enthusiasts",

    "Collaborate with our development team for innovative applications",
    "Utilize SQL adn no-SQL databases to enhance applications",
    "Contribute to the evolving field of data-driven applications",
    "Perfect chance for aspiring developers to make a digital mark",
    "The amalgamation of technology, innovation, and proficiency in a modern tech stack",
  ];

  const appDeveloperSubData = [
    "UI Developers",
    "Back-end Developers",
    "Full-stack Developers",
    "Database Developers",
    "Power BI Developer",
  ];

  const columns = [
    {
      sm: 3,
      iconSrc: "/icons/field_icon.svg",
      iconClassName: "icon_trans",
      subTitle: "Choose Field",
      description: "Select your desired field from available vacancies",
      width: "64px",
      height: "64px",
    },
    {
      sm: 1,
      iconSrc: "/icons/curve_icon.svg",
      iconClassName: "icon_curve",
      width: "38px",
      height: "38px",
    },
    {
      sm: 3,
      iconSrc: "/icons/fillForm_icon.svg",
      iconClassName: "icon_trans",
      subTitle: "Fill Form",
      description: "Fill out the required information in the form",
      width: "64px",
      height: "64px",
    },
    {
      sm: 1,
      iconSrc: "/icons/curve_icon.svg",
      iconClassName: "icon_curve",
      width: "38px",
      height: "38px",
    },
    {
      sm: 3,
      iconSrc: "/icons/allDone_icon.svg",
      iconClassName: "icon_trans",
      subTitle: "All Done!",
      description: "Now just wait for the interview call for final selection",
      width: "64px",
      height: "64px",
    },
  ];

  return (
    <Container>
      <MetaHelmet {...metaHelmetData.sql_power_bi_software_it_job_internship} />
      <div className="row">
        <h1 className="heading">Internships</h1>
        <p className="description text-sm-left text-lg-center">
          Our internship program opens doors to a wide range of fields, with a
          proven track record of success. Explore the sections below to learn
          more about our internship statistics, training assistant roles, app
          developer positions, and our streamlined application process.
        </p>
      </div>

      <Row fluid={true} className="pt-lg-5 pt-sm-5">
        <Col className="text-sm-center text-lg-start" xs={12} lg={6}>
          <Image preview={false}
            className="img"
            src="/images/statistics.svg"
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </Col>
        <Col className="pt-2 d-flex justify-content-center" xs={12} lg={6}>
          <div className="wd">
            <div className="sub-title">
              <span style={{ marginRight: "3px" }}>
                <Image preview={false}
                  className="icon"
                  src="/icons/statistics_icon.svg"
                  alt="i"
                  width="20px"
                  height="20px"
                />
              </span>
              Statistics
            </div>
            <p className="title">
              10+ Students Connected to Real-World Experience
            </p>
            <p className="description">
              At Tansy Academy, we take pride in our commitment to nurturing
              talent and fostering connections between our students and industry
              professionals. Our internship program has successfully placed over
              10+ students in various fields, including but not limited to:
            </p>
            <Row className="pt-4 gy-3">
              {statisticsData?.map((item, index) => (
                <Col xs={6} key={index}>
                  <p className="sub_desc mb-sub_desc">{item.title}</p>
                  <p className="sub_text">{item.internships} internships</p>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="pt-5">
        <Col
          xs={12}
          lg={6}
          className="text-sm-center text-lg-end order-1 order-lg-2"
        >
          <Image preview={false}
            className="img"
            src="/images/coding_Experience.svg"
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </Col>
        <Col className="pt-2 d-flex justify-content-center order-2 order-lg-1">
          <div className="wd">
            <p className="sub-title">
              <span style={{ marginRight: "3px" }}>
                <Image preview={false}
                  className="icon"
                  src="/icons/path_icon.svg"
                  alt="i"
                  width="20px"
                  height="20px"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </span>
              Path to LIVE web application CODING Experience
            </p>
            <p className="title">Shaping Future Professionals</p>
            <div className="text-left ul_description">
              <ul>
                {codingExperienceData?.map((item, index) => (
                  <li component="li" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="pt-5">
        <Col xs={12} lg={6} className="text-sm-center text-lg-start">
          <Image preview={false}
            className="img"
            src="/images/app_Developer.svg"
            alt="i"
            width="100%"
            height="100%"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </Col>
        <Col className="pt-2 d-flex justify-content-center">
          <div className="wd">
            <p className="sub-title">
              <span style={{ marginRight: "5px" }}>
                <Image preview={false}
                  className="icon"
                  src="/icons/people_icon.svg"
                  alt="i"
                  width="20px"
                  height="20px"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </span>
              App Developer Positions
            </p>
            <p className="title">Innovate with Us</p>
            <div className="text-left ul_description">
              <ul>
                {appDeveloperData?.map((item, index) => (
                  <li component="li" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="container">
              <p className="sub_text">15 internships</p>
              <div className="row gx-3 gy-3">
                {appDeveloperSubData?.map((item, index) => (
                  <Col xs={6} key={index}>
                    <li className="sub_desc">{item}</li>
                  </Col>
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className=" pt-5 justify-content-center">
        <Col xs={12} className="text-center">
          <div className="d-flex justify-content-center">
            <p className="sub-title">
              <span style={{ marginRight: "5px" }}>
                <Image preview={false}
                  className="icon"
                  src="/icons/process_icon.svg"
                  alt="i"
                  width="20px"
                  height="20px"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </span>
              Process
            </p>
          </div>
          <h2 className=" mb-2 title">
            Your Path to a Transformative Experience
          </h2>

          <Row className="mt-5 pb-5 justify-content-center align-items-center">
            {columns?.map((column, index) => (
              <Col sm={column.sm} key={index} className="text-center">
                {column.iconSrc && (
                  <Image preview={false}
                    className={column.iconClassName}
                    src={column.iconSrc}
                    alt="i"
                    max-width={column.width}
                    max-height={column.height}
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                )}
                {column.subTitle && (
                  <p className="sub-title text-center">{column.subTitle}</p>
                )}
                {column.description && (
                  <p className="description text-center">
                    {column.description}
                  </p>
                )}
              </Col>
            ))}
          </Row>

          <div className=" mt-4 mb-5 d-flex justify-content-center">
            <Link to="/contact" className="searchBtn">
              <CustomBtn title="Register now" />
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Internship;
