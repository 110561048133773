import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const getCourseQuiz = createAsyncThunk(
  "questions/getCourseQuiz",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getCourseQuiz(courseId);
      if (response.status === 200) {
        dispatch(getQuizList());
        return response?.data?.data;
      }
    } catch (error) {
      console.error(error);
      throw rejectWithValue(error);
    }
  }
);

export const getPublicCourseQuiz = createAsyncThunk(
  "questions/getPublicCourseQuiz",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPublicCourseQuiz(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      console.error(error);
      throw rejectWithValue(error);
    }
  }
);
export const getPublicQuizQuestions = createAsyncThunk(
  "questions/getPublicQuizQuestions",
  async ({ courseName, quizShortName }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPublicQuizQuestions(
        courseName,
        quizShortName
      );
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const addQuiz = createAsyncThunk(
  "questions/addQuiz ",
  async (
    { formDataObject, courseId, onReset },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addQuiz(formDataObject);
      if (response.status === 200) {
        onReset();
        successMsg("Question Added!");
        dispatch(getCourseQuiz(courseId));
        return response?.data?.data;
      } else {
        errorMsg("Please try again.");
      }
    } catch (error) {
      errorMsg(error);
      throw rejectWithValue(error);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "questions/addQuestion ",
  async (
    { formDataObject, quizId, navigate, onReset },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addQuestion(formDataObject, quizId);
      if (response.status === 200) {
        onReset();
        successMsg("Question Added!");
        return response?.data?.data;
      } else {
        errorMsg("Please try again.");
      }
    } catch (error) {
      errorMsg(error);
      throw rejectWithValue(error);
    }
  }
);

export const getQuizQuestions = createAsyncThunk(
  "questions/getQuizQuestions",
  async ({ quizId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getQuizQuestions(quizId);
      return response?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateQuizFilled = createAsyncThunk(
  "questions/updateQuizFilled ",
  async (
    { quizId, data, onReset},
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateQuizFilled(quizId, data);
      if (response.status === 200) {
        dispatch(getQuizQuestions({ quizId }));
        onReset();
        successMsg(response.data.status);
        return;
      }
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getQuestionById = createAsyncThunk(
  "questions/getQuestion",
  async ({ quizId, questionId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getQuestion(quizId, questionId);
      return response?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getQuestionCountsByTag = createAsyncThunk(
  "questions/getQuestionCountsByTag",
  async ({ quizId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getQuestionCountsByTag(quizId);
      return response?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getQuizTagSnapshot = createAsyncThunk(
  "questions/quizTagSnapshot",
  async ({ quizId, tag }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.quizTagSnapshot(quizId, tag);
      return response?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getQuizList = createAsyncThunk(
  "questions/getQuizList",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getQuizList();
      return response?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const handleActiveFlag = createAsyncThunk(
  "questions/handleActiveFlag",
  async ({ quizId, courseId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.handleActiveFlag(quizId, data);
      dispatch(getCourseQuiz(courseId));
      return response?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const removeQuestion = createAsyncThunk(
  "questions/removeQuestion",
  async ({ Id, QuestionId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.removeQuestion(Id, QuestionId);
      if (response.status === 200) {
        successMsg("Question Deleted!");
        return response;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const toggleQuestionVisibility = createAsyncThunk(
  "questions/toggleQuestionVisibility",
  async ({ Id, QuestionId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.toggleQuestionVisibility(
        Id,
        QuestionId,
        data
      );
      if (response.status === 200) {
        dispatch(getQuizQuestions({ quizId: Id }));
        successMsg("Updated Question Visibility!");
        return;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const initialState = {
  loadingQuestions: false,
  loading: false,
  quizQuestions: {},
  retrieveQuestion: {},
  publicCourseQuiz: [],
  courseQuiz: [],
  quizTagSnapshot: [],
  quizList: [],
  tagQuestionCount: [],
  publicQuizQuestions: {},
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublicCourseQuiz.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPublicCourseQuiz.fulfilled, (state, action) => {
        state.publicCourseQuiz = action.payload;
        state.loading = false;
      })
      .addCase(getPublicCourseQuiz.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuestionCountsByTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionCountsByTag.fulfilled, (state, action) => {
        state.tagQuestionCount = action.payload;
        state.loading = false;
      })
      .addCase(getQuestionCountsByTag.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuizList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuizList.fulfilled, (state, action) => {
        state.quizList = action.payload;
        state.loading = false;
      })
      .addCase(getQuizList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCourseQuiz.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourseQuiz.fulfilled, (state, action) => {
        state.courseQuiz = action.payload;
        state.loading = false;
      })
      .addCase(getCourseQuiz.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuizTagSnapshot.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuizTagSnapshot.fulfilled, (state, action) => {
        state.quizTagSnapshot = action.payload;
        state.loading = false;
      })
      .addCase(getQuizTagSnapshot.rejected, (state) => {
        state.loading = false;
      })
      .addCase(handleActiveFlag.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleActiveFlag.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(handleActiveFlag.rejected, (state) => {
        state.loading = false;
      })
      .addCase(toggleQuestionVisibility.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleQuestionVisibility.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(toggleQuestionVisibility.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addQuiz.pending, (state) => {
        state.loading = true;
      })
      .addCase(addQuiz.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addQuiz.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateQuizFilled.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuizFilled.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateQuizFilled.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addQuestion.pending, (state) => {
        state.loadingQuestions = true;
      })
      .addCase(addQuestion.fulfilled, (state) => {
        state.loadingQuestions = false;
      })
      .addCase(addQuestion.rejected, (state) => {
        state.loadingQuestions = false;
      })
      .addCase(removeQuestion.pending, (state) => {
        state.loadingQuestions = true;
      })
      .addCase(removeQuestion.fulfilled, (state) => {
        state.loadingQuestions = false;
      })
      .addCase(removeQuestion.rejected, (state) => {
        state.loadingQuestions = false;
      })
      .addCase(getQuizQuestions.pending, (state) => {
        state.loadingQuestions = true;
      })
      .addCase(getQuizQuestions.fulfilled, (state, action) => {
        state.loadingQuestions = false;
        state.quizQuestions = action.payload;
      })
      .addCase(getQuizQuestions.rejected, (state) => {
        state.loadingQuestions = false;
      })
      .addCase(getPublicQuizQuestions.pending, (state) => {
        state.loadingQuestions = true;
      })
      .addCase(getPublicQuizQuestions.fulfilled, (state, action) => {
        state.loadingQuestions = false;
        state.publicQuizQuestions = action.payload;
      })
      .addCase(getPublicQuizQuestions.rejected, (state) => {
        state.loadingQuestions = false;
      })
      .addCase(getQuestionById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionById.fulfilled, (state, action) => {
        state.loading = false;
        state.retrieveQuestion = action.payload;
      })
      .addCase(getQuestionById.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default questionsSlice.reducer;
