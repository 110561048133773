import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Select, Input, Form, Checkbox, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { CustomSelect } from "../../../common/select/index.style.js";
import CustomBtn from "../../../common/button/index.jsx";
import { Wrapper } from "../../Forum/manageTopic/index.style.js";
import { InfoForm } from "../../Forum/manageTopic/topicDetails/index.style.js";
import { CustomInput } from "../../../common/input/index.style.js";
import { antValidator } from "../../../utils/helper.js";
import { questionsSchema } from "../../../utils/validationSchema.js";
import { addQuestion, getQuestionById } from "../../../redux/questionsSlice.js";
import { Helmet } from "react-helmet";
import { BsChevronLeft } from "react-icons/bs";
import { BackBtn } from "../../Forum/forumList/threadQuestion/index.style.js";

const EditQuizQuestion = () => {
  const { TextArea } = Input;
  const { quizId, questionId } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { loadingQuestions, loading, retrieveQuestion } = useSelector(
    (state) => state.questions
  );
  const editQuestionRef = useRef(null);
  const [rules, setRules] = useState({});
  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    dispatch(getQuestionById({ quizId, questionId }));
    setRules(antValidator(questionsSchema));
  }, [dispatch, quizId, questionId]);

  useEffect(() => {
    if (!retrieveQuestion?.question || !editQuestionRef.current) return;

    const {
      questionType,
      Options,
      questionName,
      questionImageURL,
      codingQuestion,
      explanation,
      isQuestionPublic,
      _id,
    } = retrieveQuestion?.question;

    const getOptions = () => {
      switch (questionType) {
        case "True/False":
          return ["OptionA", "OptionB"];
        case "Checkbox":
        case "Radio Button":
          return ["OptionA", "OptionB", "OptionC", "OptionD"];
        default:
          return [];
      }
    };

    const getAnswerFlags = () => {
      if (questionType === "True/False") {
        return [Options?.[0]?.answerFlag ? "optionA" : "optionB"];
      }

      return Options?.reduce((acc, option, index) => {
        if (option?.answerFlag) {
          acc.push(`option${String.fromCharCode(65 + index)}`);
        }
        return acc;
      }, []);
    };

    const options = getOptions();
    const answerFlags = getAnswerFlags();

    const formValues = {
      _id,
      questionPublicFlag: isQuestionPublic,
      writeQuestion: questionName,
      questionType,
      codingQuestion,
      enterImageLink: questionImageURL,
      explanation,
      ...options.reduce(
        (acc, option, index) => ({
          ...acc,
          [option]: Options?.[index]?.optionName || "",
        }),
        {}
      ),
      answer: answerFlags,
    };

    editQuestionRef.current.setFieldsValue(formValues);
    setQuestionType(questionType);
  }, [retrieveQuestion, editQuestionRef]);

  const optionsQuestionType = [
    { value: "Checkbox", label: "Checkbox" },
    { value: "True/False", label: "True/False" },
    { value: "Radio Button", label: "Radio Button" },
  ];

  const answerFlagOptions = {
    "True/False": [
      { label: "Option A", value: "optionA" },
      { label: "Option B", value: "optionB" },
    ],
    default: [
      { label: "Option A", value: "optionA" },
      { label: "Option B", value: "optionB" },
      { label: "Option C", value: "optionC" },
      { label: "Option D", value: "optionD" },
    ],
  };

  const FormReset = () => {
    form.resetFields();
    navigate(`/admin/quiz/${quizId}/questions`);
  };

  const handleChange = (newValue) => {
    form.setFieldsValue({ answer: [] });
    setQuestionType(newValue);
  };

  const handleQuestionsSubmit = (fieldsValue) => {
    const formDataObject = {
      questionId: questionId || null,
      action: "edit",
      isQuestionPublic: fieldsValue?.questionPublicFlag,
      questionName: fieldsValue?.writeQuestion,
      questionType: fieldsValue?.questionType,
      questionImageURL: fieldsValue?.enterImageLink || null,
      codingQuestion: fieldsValue?.codingQuestion || null,
      explanation: fieldsValue?.explanation,
    };

    switch (fieldsValue?.questionType) {
      case "True/False":
        formDataObject.optionA = fieldsValue?.OptionA;
        formDataObject.optionB = fieldsValue?.OptionB;
        break;
      default:
        formDataObject.optionA = fieldsValue?.OptionA;
        formDataObject.optionB = fieldsValue?.OptionB;
        formDataObject.optionC = fieldsValue?.OptionC;
        formDataObject.optionD = fieldsValue?.OptionD;
        break;
    }
    formDataObject.answerFlag = Array.isArray(fieldsValue?.answer)
      ? fieldsValue?.answer
      : [fieldsValue?.answer];

    dispatch(
      addQuestion({ formDataObject, quizId, onReset: FormReset })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Edit Question</title>
      </Helmet>
      <Container fluid>
        <BackBtn
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <span>
            <BsChevronLeft />
          </span>
          <span>Go back</span>
        </BackBtn>
        <Spin spinning={loading}>
          <InfoForm
            layout="vertical"
            style={{ borderBottom: "0px solid #ffff" }}
            onFinish={handleQuestionsSubmit}
            form={form}
            ref={editQuestionRef}
          >
            <center className="mb-4">
              <h2>Edit Question</h2>
            </center>
            <CustomInput
              name="writeQuestion"
              label={<strong>Write Question : </strong>}
              rules={[rules]}
            >
              <Input placeholder="Write Question" />
            </CustomInput>
            <Row gutter={[30]}>
              <Col span={12}>
                <CustomSelect
                  name="questionType"
                  label={<strong>Question Type : </strong>}
                  rules={[rules]}
                >
                  <Select
                    showSearch
                    placeholder="Select question type"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={optionsQuestionType}
                    onChange={handleChange}
                    value={questionType}
                  />
                </CustomSelect>
              </Col>
              <Col span={12}>
                <CustomInput
                  label={<strong>Image Link : </strong>}
                  name="enterImageLink"
                >
                  <Input placeholder="Enter image link" />
                </CustomInput>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomInput
                  label={<strong>Code : </strong>}
                  name="codingQuestion"
                >
                  <TextArea rows={6} placeholder="Enter Code" />
                </CustomInput>
              </Col>
            </Row>
            {questionType === "True/False" ? (
              <Row gutter={[30]}>
                <Col span={12}>
                  <CustomInput
                    label={<strong>Option A : </strong>}
                    name="OptionA"
                    rules={[rules]}
                  >
                    <TextArea placeholder="True" />
                  </CustomInput>
                </Col>
                <Col span={12}>
                  <CustomInput
                    label={<strong>Option B : </strong>}
                    name="OptionB"
                    rules={[rules]}
                  >
                    <TextArea placeholder="False" />
                  </CustomInput>
                </Col>
              </Row>
            ) : (
              <Row gutter={[30]}>
                <Col span={12}>
                  <CustomInput
                    label={<strong>Option A : </strong>}
                    name="OptionA"
                    rules={[rules]}
                  >
                    <TextArea placeholder="Option A" />
                  </CustomInput>
                </Col>
                <Col span={12}>
                  <CustomInput
                    label={<strong>Option B : </strong>}
                    name="OptionB"
                    rules={[rules]}
                  >
                    <TextArea placeholder="Option B" />
                  </CustomInput>
                </Col>
                <Col span={12}>
                  <CustomInput
                    label={<strong>Option C : </strong>}
                    name="OptionC"
                    rules={[rules]}
                  >
                    <TextArea placeholder="Option C" />
                  </CustomInput>
                </Col>
                <Col span={12}>
                  <CustomInput
                    label={<strong>Option D : </strong>}
                    name="OptionD"
                    rules={[rules]}
                  >
                    <TextArea placeholder="Option D" />
                  </CustomInput>
                </Col>
              </Row>
            )}
            <strong>Answer : </strong>
            <i>
              {questionType === "True/False"
                ? "Please select either option A or option B."
                : "Please choose from option A, option B, option C, or option D."}
            </i>
            <CustomSelect name="answer" rules={[rules]}>
              <Select
                mode={questionType === "Checkbox" ? "multiple" : "default"}
                initialValues={questionType === "Checkbox" ? [] : ""}
                placeholder="Please select Correct Answer"
                style={{ width: "100%" }}
                allowClear
                options={
                  questionType === "True/False"
                    ? answerFlagOptions[questionType]
                    : answerFlagOptions.default
                }
              />
            </CustomSelect>
            <CustomInput
              name="explanation"
              label={<strong>Explanation : </strong>}
              rules={[rules]}
            >
              <TextArea rows={6} placeholder="Explanation" />
            </CustomInput>
            <Form.Item
              className="checkingArea"
              name="questionPublicFlag"
              valuePropName="checked"
              rules={[rules]}
            >
              <Checkbox>
                Allow this question to be visible to the public
              </Checkbox>
            </Form.Item>
            <center>
              <CustomBtn
                htmlType="submit"
                type="submit"
                title="Save"
                loading={loadingQuestions}
                disable={loadingQuestions}
              />
            </center>
          </InfoForm>
        </Spin>
      </Container>
    </Wrapper>
  );
};

export default EditQuizQuestion;
