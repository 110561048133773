import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 1.5em 0;

  .heading {
    text-align: center;
    margin: 1em 0 1.5em;
  }

  .marquee {
    margin: 0 0 2em 0;
    padding: 10px;
    background-color: #f5f5f5;
  }

  .description-container {
    margin-bottom: 40px;
    text-align: left;
  }

  .scrollable-description {
    margin: auto;
    max-height: 200px;
    overflow: auto;
    padding-right: 20px;
  }

  .scrollable-description::-webkit-scrollbar {
    display: none;
  }

  .scrollable-description {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .project-title {
    margin: 20px 0 2px 4px;
  }

  .centered-col {
    display: flex;
    flex-direction: column;
    height: 100%; 
    flex-grow: 1;
  }

  .styled-card {
    border-radius: 8px;

    &:hover {
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    }

    .ant-card-body {
      padding: 16px;
    }

    .ant-card-cover img {
      border-radius: 8px 8px 0 0;
      transition: transform 0.3s ease;
    }
  }
  .centered{
    display: flex;
    justify-content: center;
  }
  .image {
    border-radius: 10px;
  }

  .title-paragraph {
    font-weight: 700;
    width: 350px;
    text-decoration: underline;
  }

  .description {
    width: 350px;
  }
`;
