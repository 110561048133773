import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  List,
  Typography,
  message,
  Spin,
  Card,
  Divider,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { submitUrls } from "../../../redux/urlSlice";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const SubmitUrls = () => {
  const dispatch = useDispatch();
  const {
    loading: isLoading,
    submittedUrlStatus,
    error,
  } = useSelector((state) => state.submitUrls);

  const [urlInput, setUrlInput] = useState("");
  const [submittedUrls, setSubmittedUrls] = useState([]);

  const handleInputChange = (event) => {
    setUrlInput(event.target.value);
  };

  const handleAddUrl = () => {
    const newUrls = urlInput
      .split("\n")
      .map((url) => url.trim())
      .filter(Boolean);

    const updatedUrls = [...new Set([...submittedUrls, ...newUrls])];
    setSubmittedUrls(updatedUrls);
    setUrlInput("");
  };

  const handleRemoveUrl = (urlToRemove) => {
    setSubmittedUrls(submittedUrls.filter((url) => url !== urlToRemove));
  };

  const handleSubmit = async () => {
    if (submittedUrls.length === 0) {
      message.error("Please add at least one URL before submitting.");
      return;
    }

    try {
      const data = { urlList: submittedUrls };
      await dispatch(submitUrls(data));
    } catch (error) {
      message.error(`Failed to submit URLs: ${error.message}`);
    }
  };

  useEffect(() => {
    if (submittedUrlStatus && submittedUrlStatus?.message) {
      message.success(submittedUrlStatus?.message);
      setSubmittedUrls([]);
    }

    if (error) {
      message.error(`Failed to submit URLs: ${error?.message}`);
    }
  }, [submittedUrlStatus, error]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Index Now API | Tansy Academy Admin</title>
      </Helmet>
      <Card
        style={{
          padding: "20px",
          maxWidth: "80%",
          margin: "auto",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          borderRadius: "12px",
        }}
      >
        <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
          Submit URLs to IndexNow
        </Title>
        <Form layout="vertical">
          <Form.Item
            label="Enter URLs (comma-separated or line-separated)"
            style={{ marginBottom: "15px" }}
          >
            <Input.TextArea
              value={urlInput}
              onChange={handleInputChange}
              placeholder="Enter URLs separated by commas or new lines"
              rows={5}
              style={{ borderRadius: "10px", padding: "12px" }}
            />
            <Button
              type="primary"
              onClick={handleAddUrl}
              style={{
                width: "100%",
                marginTop: "10px",
                backgroundColor: "#1890ff",
                borderRadius: "10px",
              }}
            >
              Add URLs
            </Button>
          </Form.Item>
        </Form>

        {submittedUrls.length > 0 && (
          <div>
            <Divider />
            <Title level={4} style={{ marginBottom: "10px" }}>
              Added URLs:
            </Title>
            <List
              bordered
              dataSource={submittedUrls}
              renderItem={(url) => (
                <List.Item
                  actions={[
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveUrl(url)}
                      danger
                    />,
                  ]}
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginBottom: "10px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {url}
                </List.Item>
              )}
              style={{ borderRadius: "10px", padding: "10px" }}
            />
          </div>
        )}

        <Form.Item style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "#52c41a",
              borderColor: "#52c41a",
            }}
            loading={isLoading}
          >
            Submit URLs
          </Button>
        </Form.Item>

        {isLoading && (
          <Spin style={{ display: "block", margin: "20px auto" }} />
        )}
        {error && (
          <Text type="danger" style={{ marginTop: "10px", display: "block" }}>
            {error.message || "An error occurred while submitting."}
          </Text>
        )}
      </Card>
    </React.Fragment>
  );
};

export default SubmitUrls;
