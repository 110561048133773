import React from "react";
import { Button, Result } from "antd";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const CancelPage = () => {
  const navigate = useNavigate();
  const handleSubscriptionRedirect = () => {
    navigate("/learn_sql_power_bi_analytics_online_training_cost");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleSupportContact = () => {
    navigate("/contact");
  };


  return (
    <>
      <Helmet>
        <title>Tansy Academy | Payment Cancelled</title>
      </Helmet>
      <Result
        status="warning"
        title="Payment Cancelled"
        subTitle="Your payment has been cancelled. If this was a mistake, you can retry the payment or explore other subscription options."
        extra={[
          <Button
            type="primary"
            key="subscriptions"
            onClick={handleSubscriptionRedirect}
          >
            View Subscription Plans
          </Button>,
          <Button
            key="home"
            onClick={handleGoHome}
          >
            Back to Home
          </Button>,
          <Button
            key="support"
            type="default"
            onClick={handleSupportContact}
          >
            Contact Support
          </Button>,
        ]}
      />
    </>
  );
};

export default CancelPage;
