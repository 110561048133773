import { Link } from "react-router-dom";

export const sectionsData = [
  {
    id: 1,
    title: "Key takeaways from our SQL training courses:",
    image: "/images/Key-takeaways-from-our -SQL-training-courses.svg",
    items: [
      "Increased awareness of various software applications.",
      <>
        Fundamental understanding of data and{" "}
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-database-components">
          databases
        </Link>
        .
      </>,
      "Knowledge of SQL concepts.",
      <>
        Completion of an{" "}
        <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-student-management-system">
          SQL capstone project
        </Link>
        .
      </>,
      "Job search strategies, resume building, and interview skills.",
      <>
        During our SQL course you will be having the opportunity to concentrate
        on any one of the databases like{" "}
        <Link to="https://tansyacademy.com/course_detail?name=MySQL">
          MySQL
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/course_detail?name=PostgreSQL">
          PostgreSQL
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/course_detail?name=Microsoft%20SQL%20Server">
          MS SQL Server
        </Link>{" "}
        and{" "}
        <Link to="https://tansyacademy.com/course_detail?name=Oracle">
          Oracle
        </Link>
      </>,
    ],
  },
  {
    id: 2,
    title: "Learn SQL",
    image: "/images/Learn-SQL.svg",
    items: [
      "At tansy we have different training levels through which a student can crack SQL developer jobs.",
      <>
        If you're new to SQL, make sure you start here with our most popular{" "}
        <Link to="https://tansyacademy.com/courses/sql_database_programming_tutorial_courses">
          SQL course
        </Link>{" "}
        for beginners.
      </>,
      "We are specialised in training students from non IT background or bachelor students with zero experience.",
      "All our training levels are meant for you to progress in the course and stay supported even after you join your first IT job as SQL professional.",
      <>
        The vast majority of software applications that use data, are backed by
        a good relational database system. This course will help to establish a
        base to those who want to begin learning{" "}
        <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/mysql-structured-query-language-db-database-sql">
          Structured Query Language (SQL)
        </Link>
      </>,
    ],
  },
];

export const dataLevel = [
  {
    title: "Level 1",
    heading: "Live SQL Classes",
    content: (
      <>
        We will provide morning and evening batches to accommodate students
        based on their busy schedules. During their{" "}
        <Link to="https://tansyacademy.com/learn_sql_power_bi_analytics_online_training_cost">
          subscription
        </Link>{" "}
        period, students can attend multiple{" "}
        <Link to="https://tansyacademy.com/learn_sql_class_online_sql_training_course">
          SQL batches/classes
        </Link>
        .
      </>
    ),
  },
  {
    title: "Level 2",
    heading: "Online SQL Content",
    content: (
      <>
        All{" "}
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-application-software">
          sql course content
        </Link>{" "}
        will be hosted online, including over 1000+ sample SQL queries available
        throughout the duration of the course. Lifetime access to the static
        course content will be provided free of charge.
      </>
    ),
  },
  {
    title: "Level 3",
    heading: "Online SQL Videos",
    content: (
      <>
        During the valid subscription period, students will have access to
        premium{" "}
        <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-group-by">
          sql video content
        </Link>{" "}
        for each lesson. Class videos will be uploaded to their respective
        lessons online.
      </>
    ),
  },
  {
    title: "Level 4",
    heading: "Online SQL Query Editor (live sql tool)",
    content: (
      <>
        We offer a comprehensive and robust online{" "}
        <Link to="https://tansyacademy.com/online_sql_query_editor_execution">
          SQL query tool
        </Link>{" "}
        that provides a user-friendly interface and a wide range of features to
        enhance your database querying experience. With our tool, you can write
        and execute SQL queries efficiently, visualize query results, and
        analyze data seamlessly, making it an indispensable resource for
        database professionals and learners alike.You can bypass the painstaking
        process of installing MySQL Server, Postgres database, Oracle, or MSSQL
        Server on your computers by utilizing our online SQL query tool to
        execute queries against these databases seamlessly.
      </>
    ),
  },
  {
    title: "Level 5",
    heading: "Online SQL Quiz",
    content: (
      <>
        Throughout our SQL training course, you'll encounter over 4000 quiz
        questions to test your knowledge and gauge your progress. This approach
        allows you to measure your skills and build confidence in securing a new
        job as an SQL developer. Our quiz questions will be common across
        postgresql dbms (pgsql), msft sql server, mysql db and other db ms sql{" "}
        <Link to="https://tansyacademy.com/mysql/sql-certifications/sql-training-mysql-certifications">
          certification course
        </Link>{" "}
        like oracle.
      </>
    ),
  },
  {
    title: "Level 6",
    heading: "Database Projects and Assignments",
    content: (
      <>
        You will gain valuable hands-on experience by completing our 15+ mini
        assignments/{" "}
        <Link to="https://tansyacademy.com/sql_database_projects">
          sql projects
        </Link>
        .
      </>
    ),
  },
  {
    title: "Level 7",
    heading: "Live webapp coding",
    content: (
      <>
        During the course, you will have exposure to real-world{" "}
        <Link to="https://tansycloud.com">LIVE web applications</Link> and will
        be provided with REAL TIME SQL code examples for most of the SQL topics
        covered in the curriculum.
      </>
    ),
  },
  {
    title: "Level 8",
    heading: "SQL Capstone Project",
    content: (
      <>
        You will participate in two{" "}
        <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-student-management-system">
          real-world sql projects:
        </Link>{" "}
        one guided during Zoom classes and a final project to demonstrate your
        SQL skills independently.
      </>
    ),
  },
  {
    title: "Level 9",
    heading: "Personal Coach/Mentor",
    content: (
      <>
        You will be paired with a dedicated personal mentor who will provide
        additional guidance to help you complete your{" "}
        <Link to="https://tansyacademy.com/sql_database_projects">
          sql assignments
        </Link>
        .
      </>
    ),
  },
  {
    title: "Level 10",
    heading: "Mock Interview",
    content: (
      <>
        We will engage you in a couple of mock interviews to boost your
        confidence and prepare you for real-time{" "}
        <Link to="https://tansyacademy.com/mysql/database_interview_questions">
          interviews
        </Link>{" "}
        , increasing your ability to excel and succeed in them.
      </>
    ),
  },
  {
    title: "Level 11",
    heading: "Online SQL Forum",
    content: (
      <>
        An online{" "}
        <Link to="https://tansyacademy.com/forum-threads">SQL forum</Link>{" "}
        provides a collaborative platform where users can ask questions, share
        knowledge, and engage with a community of SQL enthusiasts and
        professionals. It's a valuable resource for troubleshooting SQL-related
        issues, learning new techniques, and staying updated on industry trends.
      </>
    ),
  },
  {
    title: "Level 12",
    heading: "WhatsApp and Slack chats",
    content: (
      <>
        We offer support to our SQL course students through dedicated{" "}
        <Link to="https://wa.me/+971504614868">WhatsApp</Link> and Slack chats,
        providing real-time assistance, clarifications, and additional guidance
        beyond the classroom. These communication channels facilitate
        interactive discussions and foster a supportive learning community among
        students and instructors.
      </>
    ),
  },
  {
    title: "Level 13",
    heading: "Power BI",
    content: (
      <>
        After completing the SQL query{" "}
        <Link to="https://tansyacademy.com/courses/sql_database_programming_tutorial_courses">
          training courses
        </Link>{" "}
        , students will have the opportunity to pursue Power BI, enabling them
        to target various job roles such as BI analyst, Power BI developer, and
        Reports developer.
      </>
    ),
  },
  {
    title: "Level 14",
    heading: "Internship",
    content: (
      <>
        Students with premium subscriptions will undergo additional screening to
        qualify for{" "}
        <Link to="https://tansyacademy.com/sql_power_bi_software_it_job_internship">
          internship
        </Link>{" "}
        opportunities at Tansy Cloud, a software development firm.
      </>
    ),
  },
  {
    title: "Level 15",
    heading: "One Family",
    content: (
      <>
        We consider our students as part of our extended family, sharing a
        common <Link to="https://tansyacademy.com/about">goal</Link> of securing
        new jobs in the IT field. Throughout this journey, you will have our
        full support, and we encourage you to stay connected even after securing
        a job to support our community
      </>
    ),
  },
];

export const syllabusData = [
  {
    header: "Chapter 1",
    main: "Course Introduction",
    key: "1",
    lesson: "Lessons",
    lessontext: (
      <Link to="https://tansyacademy.com/mysql/sql-database-learning-training-intro/live-sql-online-training-program-database-course-for-beginners-and-advanced-expert-level">
        Introduction
      </Link>
    ),
    assign: "Assignments",
    assigntext: "-",
  },
  {
    header: "Chapter 2",
    main: "Introduction to Software Applications",
    key: "2",
    lesson: "Lessons",
    lessontext: (
      <>
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-training-application-software">
          Application Software
        </Link>
        , Software Development Life Cycle, Database Components, Database
        Platforms, GUI tools for SQL development, Tansy Cloud Business
        Application
      </>
    ),
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-database-project-assignment-zoom-business-application">
          Analyse Business Applciations
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-database-project-assignment-facebook-social-application">
          Analyse Social Applciations
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/software-applications-introduction/sql-database-project-assignment-whatsapp-mobile-application">
          Analyse Mobile Applciations
        </Link>
      </>
    ),
  },
  {
    header: "Chapter 3",
    main: "Database Tables",
    key: "3",
    lesson: "Lessons",
    lessontext: (
      <>
        Columns and Rows - Database Table,{" "}
        <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/sql-database-table">
          Database
        </Link>{" "}
        Columns and Rows, What is SQL?
      </>
    ),
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-company-management-system">
          List Tables and Columns - School App
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/database-tables-columns-rows/sql-database-project-assignment-list-tables-columns-student-management-system">
          List Tables and Columns - Online Store App
        </Link>
      </>
    ),
  },
  {
    header: "Chapter 4",
    main: "Setup Sample Database",
    key: "4",
    lesson: "Lessons",
    lessontext: (
      <>
        Online{" "}
        <Link to="https://tansyacademy.com/mysql/setup-sample-database/online-sql-editor-mysql-query-tool">
          SQL Editor
        </Link>
        , Database Hosting
      </>
    ),
    assign: "Assignments",
    assigntext: "-",
  },
  {
    header: "Chapter 5",
    main: (
      <>
        <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-create-table">
          DDL
        </Link>{" "}
        Data Definition Language
      </>
    ),
    key: "5",
    lesson: "Lessons",
    lessontext: (
      <>
        CREATE TABLE , ALTER TABLE, DROP TABLE, Data Types, JSON Data Type, NULL
        vs NOT NULL, CHECK constraint, DEFAULT constraint, UNIQUE constraint,
        Auto Increment
      </>
    ),
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-ddl-assignment-company-management-system-project">
          DDL Assignment - Book Store
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/data-definition-language-sql-ddl/sql-ddl-assignment-student-management-system-project">
          DDL Assignment - Bus Trip
        </Link>
      </>
    ),
  },
  {
    header: "Chapter 6",
    main: (
      <>
        <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/sql-insert">
          DML
        </Link>{" "}
        Data Manipulation Language
      </>
    ),
    key: "6",
    lesson: "Lessons",
    lessontext: "INSERT, SELECT INTO, UPDATE, UPSERT, DELETE, Truncate",
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/dml-assignment-company-management-system-sql-database-project">
          DML Assignment - Book Store
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/data-manipulation-language-sql-dml/dml-assignment-student-management-system-sql-database-project">
          DML Assignment - Bus Trip
        </Link>
      </>
    ),
  },
  {
    header: "Chapter 7",
    main: (
      <>
        <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/sql-select-all">
          DQL
        </Link>{" "}
        Data Query Language
      </>
    ),
    key: "7",
    lesson: "Lessons",
    lessontext:
      "SELECT *, WHERE Clause, LIMIT, DISTINCT, ORDER BY, BETWEEN, CONCAT, IN, NOT IN, IS NULL, IS NOT NULL, OR, AND clause, > and < operator, <> or != (not equal), = (equal), LIKE, NOT LIKE, Select data using EXISTS, ANY, ALL, ALIAS output columns, GROUP BY, HAVING, COUNT, SUM, AVG, MIN, MAX, SQL CASE statement, INNER JOIN, LEFT JOIN, RIGHT JOIN, UNION, UNION ALL, SQL PIVOT, SQL subquery, Group Rank, Select JSON data, Arithmetic Operators, Comparison Operators, Logical Operators, STRING functions, DATE functions, NUMERIC functions, SQL Execution Order, CAST, COALESCE",
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-company-management-system-sql-database-project">
          DQL Assignment - Book Store
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-student-management-system-sql-database-project">
          DQL Assignment - Bus Trip
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/data-query-language-sql-dql/dql-assignment-logic-dashboard-reporting-company-management-system-sql-database-project">
          DQL Assignment - Dashboard
        </Link>
      </>
    ),
  },
  {
    header: "Chapter 8",
    main: (
      <>
        <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-what-is-rdbms-relational-database-management-system">
          RDBMS
        </Link>{" "}
        Concepts
      </>
    ),
    key: "8",
    lesson: "Lessons",
    lessontext:
      "RDBMS, Primary key, Foreign key, Cardinality, Normalization - First Normal Form, Second Normal Form, Third Normal Form, Business key, Natural key, Candidate Key, Surrogate Key, Database Index, Index Types, Structured VS Unstructured, ERD (Data Model)",
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/database-sql-rdbms-concepts/sql-erd-data-model-diagram-database-project-company-management-system">
          Data Model Assignment - Book Store
        </Link>
      </>
    ),
  },
  {
    header: "Chapter 9",
    main: "Advanced Topics",
    key: "9",
    lesson: "Lessons",
    lessontext: (
      <>
        <Link to="https://tansyacademy.com/mysql/sql-database-advanced-db-training-topics/sql-database-view">
          Database View
        </Link>
        , Database Stored Procedure, Database Function, Database Trigger
      </>
    ),
    assign: "Assignments",
    assigntext: "-",
  },
  {
    header: "Chapter 10",
    main: "Projects",
    key: "10",
    lesson: "Lessons",
    lessontext:
      "SQL-Supported Project (Gmail App), SQL-Capstone Project (Banking App)",
    assign: "Assignments",
    assigntext: (
      <>
        <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-company-management-system">
          Gmail App
        </Link>
        ,{" "}
        <Link to="https://tansyacademy.com/mysql/sql-database-training-projects/sql-database-final-capstone-project-student-management-system">
          Bank App
        </Link>
      </>
    ),
  },
];

export const jobRolesData = [
  {
    iconSrc: "/icons/sql-dev-icon.svg",
    title: "SQL Developer",
    description:
      "Develop and manage SQL databases by planning, developing, and maintaining the databases.",
  },
  {
    iconSrc: "/icons/data-analyst-icon.svg",
    title: "Database Developer",
    description:
      "Administering and troubleshooting computer databases and keep it secure.",
  },
  {
    iconSrc: "/icons/data-analyst-icon.svg",
    title: "Data Analyst",
    description:
      "Review data to identify key insights into a business's customers and ways the data can be used.",
  },
];

export const certificationsData = [
  {
    iconSrc: "/icons/microsoft-certifications.svg",
    title: "Microsoft Certified: Azure Data Fundamentals",
    description:
      "This certification is an opportunity to demonstrate your knowledge of core data concepts and related Microsoft Azure data services.",
    color: " rgba(0, 100, 181, 1)",
  },
  {
    iconSrc: "/icons/oracle-certifications.svg",
    title: "Oracle Database SQL Certified Associate (1Z0-071)",
    description:
      "The 1Z0-071 certification is an Oracle Database SQL certification that verifies an individual's understanding of SQL database concepts and skills.",
    color: " rgba(229, 17, 16, 1)",
  },
  {
    iconSrc: "/icons/mysql-certifications.svg",
    title: "MySQL Database Developer Certified Professional",
    description:
      "This Certification demonstrates that you have a solid understanding of your job role and of MySQL products. Consultants, developers and DBAs use Oracle.",
    color: " rgba(72, 159, 179, 1)",
  },
];

export const testimonialsData = [
  {
    id: 1,
    date: "March 5, 2023",
    message:
      "I found the MySQL course extremely beneficial. It provided all the necessary knowledge and skills I needed. Highly recommended!",
    occupation: "Student",
    name: "John Johnson",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 2,
    date: "April 10, 2023",
    message:
      "The MySQL course surpassed my expectations. It's a must for anyone aspiring to master database management.",
    occupation: "Student",
    name: "Emily Brown",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 3,
    date: "June 20, 2023",
    message:
      "I couldn't be happier with the MySQL course. It equipped me with valuable skills that are applicable in my field.",
    occupation: "Student",
    name: "Michael Davis",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 4,
    date: "July 3, 2023",
    message:
      "The MySQL course provided a seamless learning experience. I achieved exactly what I aimed for. Thank you!",
    occupation: "Student",
    name: "Sarah White",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 5,
    date: "August 15, 2023",
    message:
      "I was impressed by the quality of the MySQL course. It has motivated me to delve deeper into database management.",
    occupation: "Student",
    name: "David Lee",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
  {
    id: 6,
    date: "September 25, 2023",
    message:
      "The MySQL course offers an intuitive learning platform. I highly recommend it to anyone interested in databases.",
    occupation: "Student",
    name: "Alice Clark",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocJti_KlbC3ph-rqHBOYmdoNDTY_1xLul9L3U3adnaCoEA=s96-c",
  },
];
