import styled from "styled-components";

export const Wrapper = styled("div")`
  margin: 20px auto;
  padding: 2em 0;
  max-width: 90%;
  
  .mainHeading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h3 {
      text-transform: uppercase;
      color: #313131;
      font-weight: 600;
      font-size: 22px;
    }
    .total {
      font-weight: 400;
      color: #31313160;
      font-size: 16px;
    }
    .ant-form-item {
      width: 350px;
    }
  }
  .subscriptionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem 1rem 0;
    margin: 0.5rem 0;

    .subscriptionBox {
      background-color: #d393312b;
      padding: 0rem 2rem 0.5rem 1rem;
      display: flex;
      justify-content: start;
      gap: 1rem;

      p {
        color: #d39331;
        padding-top: 0.4rem;
      }
      .subscriptionIcon {
        color: #d39331;
        font-size: 1.25rem;
        margin-top: 0.6rem;
      }
    }
  }
  @media (max-width: 992px) {
  margin: 20px auto;
  padding: 20px auto;
  max-width: 95%;
  justify-content: space-between;
  .mainHeading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
