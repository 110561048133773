import { Select, Space, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { Container } from "react-bootstrap";
// import CommonSelect from "../../../common/select";
import { Wrapper, AddBtn } from "./index.style";
import { Link } from "react-router-dom";
// import CustomPagination from "../../../common/pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  getAdminCourses,
  // getSingleCourse,
  currentSelectedCourse,
} from "../../../redux/courseSlice";
import { CustomSelect } from "../../../common/select/index.style";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  getCourseLessons,
  deleteCourseLesson,
} from "../../../redux/lessonSlice";
// import { paginate } from "../../../utils/helper";
import { Helmet } from "react-helmet";
import { TableContainer } from "../../adminDataEntry/adminUserSession/index.style";
const { confirm } = Modal;

const ManageTopic = () => {
  const pageSize = 100;
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState("");
  const store = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  const { adminCourses } = store?.course;
  const { lessonLoading, courseLessons } = store?.lesson;

  const courseOptions = [];
  adminCourses?.map((course) => {
    courseOptions.push({
      value: course?._id,
      label: course?.longName,
    });
  });

  useEffect(() => {
    setSelectedCourse(adminCourses?.[0]?._id);
    if (adminCourses?.[0]?._id) {
      dispatch(getCourseLessons(adminCourses?.[0]?._id));
      dispatch(currentSelectedCourse(adminCourses?.[0].azureFolder));
    }
  }, [adminCourses,dispatch]);

  // delete course lesson confirmation
  const confirmDeleteLesson = (lesson) => {
    confirm({
      title: "Do you Want to delete this topic?",
      icon: <ExclamationCircleFilled />,
      // content: "Some descriptions",
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        const data = {
          courseID: selectedCourse,
          lessonID: lesson?._id,
        };
        await dispatch(deleteCourseLesson(data));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const data = courseLessons?.map((lesson, index) => {
    const course = lesson.course?.map(
      ({ courseId }) =>
        courseOptions?.find((option) => option?.value === courseId)?.label
    );
    const chapterName = lesson?.course[0]?.chapterName;
    return {
      key: lesson?._id,
      id: index + 1,
      topic: lesson?.name,
      title: lesson?.title || "",
      keyword: lesson?.keywords?.length || 0,
      course: course?.join(","),
      chapter: chapterName,
      attachments: lesson?.files,
      videos: lesson?.videos,
      queries: lesson?.codes,
      actions: (
        <Space size="middle" className="deleteBtn">
          <Link to={`/admin/topic/edit/${selectedCourse}/${lesson?._id}`}>
            <img
              src="/icons/pen-icon.svg"
              alt="i"
              width="19px"
              height="19px"
              onError={(e) => {
                e.target.style.display = "none";
              }}
            />
          </Link>
          <Link
            onClick={() => {
              confirmDeleteLesson(lesson);
            }}
          >
            <img
              src="/icons/bin-icon.svg"
              alt="i"
              width="19px"
              height="19px"
              onError={(e) => {
                e.target.style.display = "none";
              }}
            />
          </Link>
        </Space>
      ),
    };
  });

  const columns = [
    {
      title: "Sequence No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "KeyWords",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
    },
    {
      title: "Chapter Name",
      dataIndex: "chapter",
      key: "chapter",
    },
    {
      title: "Attachments",
      dataIndex: "attachments",
      key: "attachments",
    },
    {
      title: "Videos",
      dataIndex: "videos",
      key: "videos",
    },
    {
      title: "Queries",
      dataIndex: "queries",
      key: "queries",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Manage Topic</title>
      </Helmet>
      <Container fluid>
        <div className="mainHeading">
          <div>
            <h3>manage Topic</h3>
            <span className="total">{courseLessons?.length} total Topics</span>
          </div>
          <div>
            <CustomSelect>
              <Select
                key={selectedCourse}
                showSearch
                placeholder="-- Select Course --"
                defaultValue={selectedCourse}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(id, a) => {
                  dispatch(getCourseLessons(id));
                  setSelectedCourse(id);
                  dispatch(currentSelectedCourse(a.label));
                }}
                options={courseOptions}
              />
            </CustomSelect>

            <Link to={`/admin/topic/add-topic/${selectedCourse}`}>
              <AddBtn>
                <GoPlus />
                <span>Add Topic</span>
              </AddBtn>
            </Link>
          </div>
        </div>
        <TableContainer
          loading={lessonLoading}
          dataSource={data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: data?.length || 0,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default ManageTopic;
